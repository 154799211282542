import { useContext } from 'react';
import { useQuery } from 'react-query';
import { UseQueryResult } from 'react-query/types/react/types';
import { identityHttpClient } from '../../../common/utils';
import { useAuth } from '../../auth';
import { useScenario } from '../../scenarios/hooks';
import { ScenarioUsersContext } from '../provider/scenario-users-context.provider';
import { UserType } from '../types';

type ResponseType = {
  totalCount: number;
  users: UserType[];
};

export const useScenarioUsersQuery = (): UseQueryResult<ResponseType, Error> => {
  const { authenticated, tokenParsed } = useAuth();
  const { id: scenarioId } = useScenario();
  const scenarioUsersContext = useContext(ScenarioUsersContext);
  const tenantId = tokenParsed?.tenant_id ? tokenParsed?.tenant_id[0] : '';

  return useQuery<ResponseType, Error>(
    ['scenario-users', scenarioId, scenarioUsersContext.pageNumber.value],
    async () => {
      const response = await identityHttpClient.get<ResponseType>(
        `/Users/scenario/${scenarioId}/${tenantId}/?PageNumber=${scenarioUsersContext.pageNumber.value}&PageSize=${10}`,
      );
      return response.data;
    },
    {
      enabled: authenticated,
      staleTime: 5000,
    },
  );
};
