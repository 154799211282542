import React from 'react';
import { Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';

type PropsType = {
  name?: string;
  sx?: SxProps;
  className?: string;
  typoSx?: SxProps;
};

export const Heading: React.FC<React.PropsWithChildren<PropsType>> = (props) => {
  return (
    <Stack
      sx={{
        alignItems: 'center',
        backgroundColor: 'background.paper',
        flexDirection: {
          sm: 'row',
        },
        justifyContent: {
          sm: 'space-between',
        },
        mt: 2,
        mx: {
          xs: 2,
          sm: 2.5,
        },
        minHeight: 65,
        ...props.sx,
      }}
    >
      {props.name && (
        <Typography
          component={'h2'}
          className={props.className}
          sx={{
            whiteSpace: 'nowrap',
            display: 'flex',
            alignItems: 'center',
            fontSize: '1.5rem',
            fontWeight: 700,
            height: {
              xs: 65,
              md: 75,
            },
            lineHeight: '1.89rem',
            width: {
              xs: '100%',
              md: 'auto',
            },
            mr: {
              xs: 0,
              md: 2,
            },
            ...props.typoSx,
          }}
        >
          {props.name}
        </Typography>
      )}
      {props.children}
    </Stack>
  );
};
