import { useMutation, UseMutationResult } from 'react-query';
import { MutationErrorType } from '../../../common/types';
import { identityHttpClient } from '../../../common/utils';

type Response = unknown;

export const useDeleteUser = (userId: string | undefined): UseMutationResult<Response, MutationErrorType> => {
  return useMutation(async () => {
    const response = await identityHttpClient.delete(`/Users/${userId}`);
    return response.data;
  });
};
