import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Container, Grid, Typography } from '@mui/material';
import { useAuth } from '../auth';
import {
  ScenarioCard,
  ScenariosHeadingLoadingSkeleton,
  ScenariosLoadingSkeleton,
  UserWelcomeLoadingSkeleton,
} from './components';
import { useScenariosQuery } from './hooks';
import { useDeviceQuery } from '../../common/hooks';

export const ScenariosPage: React.FC = () => {
  const { t } = useTranslation();
  const { tokenParsed, authenticated } = useAuth();
  const { data, isLoading } = useScenariosQuery();
  const { isMobile } = useDeviceQuery();

  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          my: isMobile ? 5 : 10,
        }}
      >
        {authenticated && tokenParsed ? (
          <Typography
            variant="h2"
            color="primary"
            sx={{
              textAlign: 'center',
              mb: 5,
              fontSize: isMobile ? '2.5rem' : null,
            }}
          >
            {`${t('home.welcome')} `}
            <Typography
              component="span"
              variant="h2"
              color="secondary"
              sx={{
                fontSize: isMobile ? '2.5rem' : null,
              }}
            >
              {tokenParsed?.name}
            </Typography>
            {'!'}
          </Typography>
        ) : (
          <UserWelcomeLoadingSkeleton />
        )}
        {isLoading && ((data || { scenarioCollection: [] })?.scenarioCollection || [])?.length ? (
          <ScenariosHeadingLoadingSkeleton />
        ) : (
          <Typography
            component="p"
            variant="subtitle1"
            color="primary"
            sx={{
              textAlign: 'center',
              mb: isMobile ? 5 : 8,
            }}
          >
            {t('home.chooseScenario')}
          </Typography>
        )}
        <Container maxWidth="xl">
          <Grid container spacing={3} justifyContent="center">
            {isLoading ? (
              <ScenariosLoadingSkeleton />
            ) : (
              data?.scenarioCollection?.map((x) => (
                <Grid
                  item
                  key={x.scenarioId}
                  xs={12}
                  sm={6}
                  lg={4}
                  xl={3}
                  sx={{
                    minHeight: (theme) => theme.spacing(18),
                  }}
                >
                  <ScenarioCard id={x.scenarioId} name={x.scenarioName} />
                </Grid>
              ))
            )}
          </Grid>
        </Container>
      </Box>
    </>
  );
};
