import { useMutation, UseMutationResult } from 'react-query';
import { MutationErrorType } from '../../../common/types';
import { identityHttpClient } from '../../../common/utils';
import { useAuth } from '../../auth';

type Body = {
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  enable: boolean;
  group: string;
};

type Response = unknown;

export const useCreateUser = (): UseMutationResult<Response, MutationErrorType, Body> => {
  const { tokenParsed } = useAuth();
  const tenantId = tokenParsed?.tenant_id ? tokenParsed?.tenant_id[0] : '';

  return useMutation(async (body) => {
    const { group, ...userData } = body;
    const response = await identityHttpClient.post(`/Users/createUserAndSetGroup/${group}/${tenantId}`, userData);
    return response.data;
  });
};
