import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { ExpandMore, ManageAccountsOutlined, Menu as MenuIcon } from '@mui/icons-material';
import {
  AppBar,
  Box,
  FormControl,
  IconButton,
  Menu,
  MenuItem,
  Select,
  Skeleton,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material';
import { Logo } from '../../components/logo';
import { routes } from '../../routes';
import { theme } from '../../theme';
import { useAuth } from '../auth';
import { useScenario } from '../scenarios/hooks';
import { navLinks } from '../side-navigation/components/nav-links';
import { TranslationContext } from '../translation';
import { TimeZoneContext } from '../scenarios/provider/timezone-context.provider';
import { useIntercom } from 'react-use-intercom';

export const Navbar: React.FC<React.PropsWithChildren> = (props) => {
  const { t } = useTranslation();
  const { currentLanguage, setCurrentLanguage } = useContext(TranslationContext);
  const [profileEl, setProfileEl] = useState<null | HTMLElement>(null);
  const [menuEl, setMenuEl] = useState<null | HTMLElement>(null);
  const { logout, tokenParsed } = useAuth();
  const scenario = useScenario();
  const location = useLocation();
  const { shutdown } = useIntercom();

  const isScenarioPage = location.pathname.includes('/scenario/');

  const handleProfileClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setProfileEl(event.currentTarget);
  };

  const handleProfileClose = () => {
    setProfileEl(null);
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuEl(null);
  };

  const handleLogout = () => {
    logout();
    shutdown();
    setMenuEl(null);
  };

  const { isLoading, timezoneToDisplay } = useContext(TimeZoneContext);

  return (
    <>
      <Box
        sx={{
          height: {
            xs: 105,
            sm: 73,
          },
        }}
      >
        <AppBar
          position="fixed"
          sx={{
            backgroundColor: 'common.white',
            justifyContent: 'center',
            height: {
              xs: 105,
              sm: 73,
            },
          }}
        >
          <Toolbar
            sx={{
              flexWrap: 'wrap',
            }}
          >
            <Link to={routes.home.link()} style={{ paddingTop: 2, paddingRight: 8 }}>
              <Logo />
            </Link>
            {isScenarioPage && (
              <Stack
                direction={'row'}
                sx={{
                  order: {
                    xs: 1,
                    sm: 0,
                  },
                  width: {
                    xs: '100%',
                    sm: 'unset',
                  },
                }}
              >
                <Typography
                  variant="h6"
                  color="primary"
                  sx={{
                    padding: '0 1.25rem 0 2rem',
                    fontSize: '0.875rem',
                    whiteSpace: 'nowrap',
                    overflowX: 'hidden',
                    textOverflow: 'ellipsis',
                    pl: {
                      xs: 0,
                      sm: undefined,
                    },
                  }}
                >
                  {scenario.name}
                </Typography>
                <Link
                  to={routes.home.link()}
                  style={{
                    lineHeight: 1.6,
                    fontWeight: 700,
                    fontSize: '0.875rem',
                    color: theme.palette.primary.main,
                  }}
                >
                  {t('navbar.change')}
                </Link>
              </Stack>
            )}
            <Stack
              direction={'row'}
              justifyContent="flex-end"
              alignItems="center"
              sx={{
                marginLeft: 'auto',
              }}
            >
              <Typography
                variant="h6"
                color="primary"
                sx={{ display: { xs: 'none', sm: 'block' }, fontSize: '0.875rem' }}
              >
                {isLoading ? <Skeleton variant="text" width={100} /> : timezoneToDisplay}
              </Typography>
              <IconButton onClick={handleProfileClick} color="primary" aria-label="profile">
                <ManageAccountsOutlined fontSize={'large'} />
              </IconButton>
            </Stack>

            <Menu
              id="simple-menu"
              anchorEl={profileEl}
              keepMounted
              open={Boolean(profileEl)}
              onClose={handleProfileClose}
            >
              <MenuItem disabled>{tokenParsed?.name}</MenuItem>

              <MenuItem onClick={handleLogout}>{t('navbar.logout')}</MenuItem>
              {!isScenarioPage && (
                <MenuItem>
                  <FormControl
                    sx={{
                      color: 'primary.main',
                      width: '100%',
                    }}
                  >
                    <Select
                      disableUnderline
                      variant="standard"
                      labelId="lang-select-label"
                      id="lang-select"
                      label={t('selectLang')}
                      value={currentLanguage}
                      onChange={(e) => setCurrentLanguage(`${e.target.value}`)}
                      IconComponent={ExpandMore}
                      sx={{
                        color: 'primary.main',
                        width: '100%',
                      }}
                    >
                      {['en', 'pl', 'es'].map((lang, index) => (
                        <MenuItem key={index} value={lang}>
                          {lang}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </MenuItem>
              )}
            </Menu>
            {isScenarioPage && (
              <>
                <IconButton
                  onClick={handleMenuClick}
                  color="primary"
                  aria-label="menu"
                  sx={{
                    display: {
                      xs: 'inline-flex',
                      md: 'none',
                    },
                  }}
                >
                  <MenuIcon fontSize={'large'} />
                </IconButton>
                <Menu
                  id="simple-menu"
                  anchorEl={menuEl}
                  keepMounted
                  open={Boolean(menuEl)}
                  onClose={handleMenuClose}
                  sx={{
                    display: {
                      xs: 'block',
                      md: 'none',
                    },
                  }}
                >
                  {navLinks.map((x) => (
                    <MenuItem
                      key={x.name}
                      sx={{
                        display: 'block',
                      }}
                    >
                      <NavLink to={x.getLink(scenario.id)} end={!!x.isExact}>
                        {x.component}
                        <Typography
                          component={'span'}
                          sx={{
                            color: 'text.secondary',
                            display: 'inline-block',
                            fontSize: 16,
                          }}
                        >
                          {t(x.name)}
                        </Typography>
                      </NavLink>
                    </MenuItem>
                  ))}
                  <MenuItem
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <FormControl>
                      <Select
                        disableUnderline
                        variant="standard"
                        labelId="lang-select-label"
                        id="lang-select"
                        label={t('selectLang')}
                        value={currentLanguage}
                        onChange={(e) => setCurrentLanguage(`${e.target.value}`)}
                        IconComponent={ExpandMore}
                        sx={{
                          color: 'primary.main',
                        }}
                      >
                        {['en', 'pl', 'es'].map((lang, index) => (
                          <MenuItem key={index} value={lang}>
                            {lang}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </MenuItem>
                </Menu>
              </>
            )}
          </Toolbar>
        </AppBar>
      </Box>
      {props.children}
    </>
  );
};
