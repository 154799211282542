import React from 'react';
import { Assessment, Description, Phone, Settings, Storage } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { CallSchedule } from '../../../common/icons';
import { routes } from '../../../routes';

type NavLinkModel = {
  getLink: (scenarioId: string) => string;
  key: string;
  name: string;
  component: React.ReactNode;
  isExact?: boolean;
};

export const navLinks: NavLinkModel[] = [
  {
    getLink: (id: string) => routes.scenario.dashboard.link({ id }),
    key: 'dashboard',
    name: 'dashboard.heading',
    component: (
      <IconButton color="primary" aria-label="dashboard">
        <Storage />
      </IconButton>
    ),
  },
  {
    getLink: (id: string) => routes.scenario.reports.link({ id }),
    key: 'reports',
    name: 'reports.heading',
    component: (
      <IconButton color="primary" aria-label="reports">
        <Assessment />
      </IconButton>
    ),
  },
  {
    getLink: (id: string) => routes.scenario.history.link({ id }),
    key: 'history',
    name: 'history.heading',
    component: (
      <IconButton color="primary" aria-label="history">
        <Description />
      </IconButton>
    ),
  },
  {
    getLink: (id: string) => routes.scenario.callsSchedule.collectionList.link({ id }),
    key: 'callsSchedule',
    name: 'callsSchedule.heading',
    component: (
      <IconButton color="primary" aria-label="calls schedule">
        <CallSchedule fill="#302f5b" />
      </IconButton>
    ),
  },
  {
    getLink: (id: string) => routes.scenario.call.link({ id }),
    key: 'call',
    name: 'call.heading',
    component: (
      <IconButton color="primary" aria-label="calls schedule">
        <Phone />
      </IconButton>
    ),
  },
  {
    getLink: (id: string) => routes.scenario.scenarioConfigurator.link({ id }),
    key: 'scenarioConfigurator',
    name: 'scenarioConfigurator.heading',
    component: (
      <IconButton color="primary" aria-label="scenario configurator">
        <Settings />
      </IconButton>
    ),
  },
  // {
  //   getLink: (id: string) => routes.scenario.users.link({ id }),
  //   key: 'scenarioUsers',
  //   name: 'scenarioUsers.heading',
  //   component: (
  //     <IconButton color="primary" aria-label="users">
  //       <Person />
  //     </IconButton>
  //   ),
  // },
];
