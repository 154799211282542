import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, FormGroup } from '@mui/material';
import { BasicModal } from '../../../../components/basic-modal';
import { useScenario } from '../../../scenarios/hooks';
import { useBatchesQuery, useCallStart } from '../../hooks';
import { BatchContext } from '../../provider';

type PropsType = {
  handleModalClose: () => void;
  modalOpen: boolean;
};

export const StartCallingModal: React.FC<PropsType> = (props) => {
  const { t } = useTranslation();
  const { mutate } = useCallStart();
  const { batchId, callingInProgress } = useContext(BatchContext);
  const scenario = useScenario();
  const batchesQuery = useBatchesQuery();

  const handleClose = () => {
    props.handleModalClose();
  };

  const handleStartCalling = () => {
    mutate(
      {
        batchId: batchId.value,
        scenarioId: scenario.id,
      },
      {
        onSuccess: () => {
          callingInProgress.set(true);
          batchesQuery.refetch();
        },
      },
    );
  };

  const modalBody = (
    <>
      <FormGroup
        row
        sx={{
          marginTop: '2rem',
          display: 'flex',
          justifyContent: 'space-between',
          columnGap: '2rem',
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={handleStartCalling}
          sx={{
            marginTop: 2,
            flexGrow: 1,
          }}
        >
          {t('callsSchedule.collection.start')}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleClose}
          sx={{
            marginTop: 2,
            flexGrow: 1,
          }}
        >
          {t('cancel')}
        </Button>
      </FormGroup>
    </>
  );

  return (
    <BasicModal
      title={t('callsSchedule.collection.startCallingModal.title')}
      modalBody={modalBody}
      handleModalClose={props.handleModalClose}
      modalOpen={props.modalOpen}
    />
  );
};
