import React, {useCallback, useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {differenceInDays} from 'date-fns';
import {ExpandMore} from '@mui/icons-material';
import {Box, FormControl, Grid, InputLabel, MenuItem, Select} from '@mui/material';
import {MAX_DATE_RANGE} from '../../../../common/constants';
import {useModal} from '../../../../common/hooks';
import {downloadFile} from '../../../../common/utils';
import {ConfirmModal} from '../../../../components/confirm-modal';
import PaginationComponent from '../../../../components/pagination-component/pagination-component';
import {useExportReportData, useReportData} from '../../hooks';
import {useReportList} from '../../hooks/use-report-list.query';
import {ReportsContext} from '../../provider/reports-context.provider';
import {ExportToFile} from '../../../../components/export-to-file';
import {FileType} from '../../../../common/enums/file-types.enums';

export const ReportsSelectBar: React.FC = () => {
  const { t } = useTranslation();
  const confirmModal = useModal();
  const reportList = useReportList();
  const { reportId, pageNumber, dateRange } = useContext(ReportsContext);
  const [selectedReport, setSelectedReport] = useState(reportId.value);
  const [modalTitle, setModalTitle] = useState('');
  const [modalMessage, setModalMessage] = useState('');
  const [fileType, setFileType] = useState(FileType.CSV);
  const reportData = useReportData(reportId.value);
  const callUseExportReportData = useExportReportData(reportId.value, fileType);
  const [totalCount, setTotalCount] = useState<number>(reportData.data?.totalCount || 0);

  const handleDownloadReport = useCallback(async () => {
    const response = await callUseExportReportData.refetch();

    downloadFile(response.data as unknown as Blob, `report.${fileType}`);
  }, [callUseExportReportData.refetch, fileType]);

  const handleClickOnDownloadReport = useCallback(() => {
    if (dateRange.value.from && dateRange.value.to) {
      if (differenceInDays(dateRange.value.to, dateRange.value.from) > MAX_DATE_RANGE) {
        setModalTitle(t('reports.defaultTitle'));
        setModalMessage(t('reports.changedDateRange', { maxDateRange: MAX_DATE_RANGE }));
        confirmModal.open();
      } else {
        handleDownloadReport();
      }
    } else {
      setModalTitle(t('reports.defaultTitle'));
      setModalMessage(t('reports.defaultDateRange'));
      confirmModal.open();
    }
  }, [confirmModal.open, handleDownloadReport, setModalTitle, setModalMessage, dateRange.value, fileType]);

  useEffect(() => {
    reportId.set(selectedReport);
  }, [selectedReport]);

  useEffect(() => {
    if (typeof reportData.data?.totalCount !== 'number') return;
    setTotalCount(reportData.data?.totalCount);
  }, [reportData.data?.totalCount]);

  useEffect(() => {
    if (!!selectedReport || !reportList.data?.reports) return;

    if (reportList.data?.reports[0]?.id) {
      setSelectedReport(reportList.data?.reports[0]?.id);
      reportId.set(reportList.data?.reports[0]?.id);
    }
  }, [reportList.data?.reports]);

  return (
    <Box
      sx={{
        width: '100%',
        paddingTop: {
          sm: 1.5,
        },
        paddingBottom: {
          sm: 1.5,
        },
        backgroundColor: 'background.paper',
      }}
    >
      <Grid container spacing={2} justifyContent={'flex-end'}>
        <Grid
          item
          sx={{
            width: {
              xs: '100%',
              md: 'unset',
            },
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: {
              sm: 'flex-end',
            },
          }}
        >
          <FormControl
            variant="outlined"
            className="intercom-select-label"
            sx={{
              width: {
                xs: '100%',
                sm: 347,
              },
            }}
          >
            <InputLabel margin="dense" htmlFor="report-select" id="report-select-label" >
              {t('reports.selectReport')}
            </InputLabel>
            <Select
              disabled={!reportList.data?.reports?.length}
              defaultValue={selectedReport || ''}
              value={selectedReport || ''}
              labelId="report-select-label"
              id="report-select"
              label={t('reports.selectReport')}
              onChange={(e) => setSelectedReport(`${e.target.value}`)}
              IconComponent={ExpandMore}
              MenuProps={{
                PaperProps: {
                  sx: {
                    marginTop: 1,
                    backgroundColor: 'common.white',
                    border: (theme) => `1px solid ${theme.palette.background.default}`,
                    borderRadius: 1,
                    boxShadow: 'none',
                  },
                },
                MenuListProps: {
                  sx: {
                    padding: 1,
                  },
                },
              }}
              sx={{
                color: 'primary.main',
                height: 48,
                minWidth: 270,
              }}
            >
              {reportList.data?.reports.map((report) => (
                <MenuItem
                  key={report.id}
                  value={report.id}
                  sx={{
                    color: 'primary.main',
                  }}
                >
                  {report.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid
          item
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: {
              sm: 'flex-end',
            },
            flexWrap: {
              xs: 'wrap-reverse',
              sm: 'nowrap',
            },
            width: {
              xs: '100%',
              sm: 'auto',
            },
          }}
        >
          {!!totalCount && (
            <PaginationComponent
              count={totalCount || 10}
              page={pageNumber.value - 1}
              pageNumber={pageNumber}
              rowsPerPage={10}
            />
          )}
          <ExportToFile
            text={t('reports.downloadBtn')}
            handleExport={handleClickOnDownloadReport}
            onSelectedFileChanged={(val) => setFileType(val)}
            selectedFile={fileType}
          ></ExportToFile>
        </Grid>
      </Grid>
      <ConfirmModal
        modalOpen={confirmModal.isOpen}
        handleModalClose={confirmModal.close}
        handleConfirm={() => handleDownloadReport()}
        message={modalMessage}
        header={modalTitle}
      />
    </Box>
  );
};
