import React from 'react';
import { Box, Skeleton } from '@mui/material';

export const HeadingSkeleton: React.FC = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        minHeight: 65,
        padding: '20px',
      }}
    >
      <Skeleton variant="text" width={400} height={60} />
      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
        <Skeleton variant="text" width={70} height={60} />
        <Skeleton variant="text" width={205} height={60} />
      </Box>
    </Box>
  );
};
