import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ExpandMore } from '@mui/icons-material';
import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import PaginationComponent from '../../../../components/pagination-component/pagination-component';
import { routes } from '../../../../routes';
import { useScenario } from '../../../scenarios/hooks';
import { CallsScheduleCollectionStatusEnum, CallsScheduleSelectOptions } from '../../data';
import { useBatchesQuery } from '../../hooks';
import { BatchListPaginationContext, CallsScheduleContext } from '../../provider';

export const CallsScheduleBar: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useScenario();
  const { selectedStatusFilter } = useContext(CallsScheduleContext);
  const navigate = useNavigate();
  const { pageNumber } = useContext(BatchListPaginationContext);
  const batches = useBatchesQuery();
  const [totalCount, setTotalCount] = useState<number>(batches.data?.totalCount || 0);

  const handleClick = () => {
    navigate(routes.scenario.callsSchedule.addCollection.link({ id }));
  };

  useEffect(() => {
    if (typeof batches.data?.totalCount !== 'number') return;
    setTotalCount(batches.data?.totalCount || 0);
  }, [batches.data?.totalCount]);

  const pageSize = 15;

  return (
    <Box
      sx={{
        width: '100%',
        paddingTop: 1.5,
        paddingBottom: 1.5,
        backgroundColor: 'background.paper',
      }}
    >
      <Grid container spacing={1} justifyContent={'flex-end'}>
        <Grid
          item
          sx={{
            width: {
              xs: '100%',
              md: 'unset',
            },
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: {
              sm: 'flex-end',
            },
          }}
        >
          <FormControl
            variant="outlined"
            className={'intercom-call-schedule-select-label'}
            sx={{
              width: {
                xs: '100%',
                sm: 347,
              },
            }}
          >
            <InputLabel margin="dense" htmlFor="collection-select" id="collection-select-label">
              {t('callsSchedule.bar.selectCollection')}
            </InputLabel>
            <Select
              labelId="collection-select-label"
              id="collection-select"
              label={t('callsSchedule.bar.selectCollection')}
              value={selectedStatusFilter.value}
              onChange={(e) => selectedStatusFilter.set(e.target.value as CallsScheduleCollectionStatusEnum)}
              IconComponent={ExpandMore}
              MenuProps={{
                PaperProps: {
                  sx: {
                    marginTop: 1,
                    backgroundColor: 'common.white',
                    border: (theme) => `1px solid ${theme.palette.background.default}`,
                    borderRadius: 1,
                    boxShadow: 'none',
                  },
                },
                MenuListProps: {
                  sx: {
                    padding: 1,
                  },
                },
              }}
              sx={{
                color: 'primary.main',
                height: 48,
                minWidth: 270,
              }}
            >
              {CallsScheduleSelectOptions().map((collection) => (
                <MenuItem
                  key={collection.name}
                  value={collection.value}
                  sx={{
                    color: 'primary.main',
                  }}
                >
                  {collection.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid
          item
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: {
              sm: 'flex-end',
            },
            flexWrap: {
              xs: 'wrap-reverse',
              sm: 'nowrap',
            },
          }}
        >
          {!!totalCount && (
            <PaginationComponent
              count={totalCount || pageSize}
              page={pageNumber.value - 1}
              pageNumber={pageNumber}
              rowsPerPage={pageSize}
            />
          )}
          <Button
            onClick={handleClick}
            color="primary"
            variant="contained"
            sx={{
              height: 48,
              whiteSpace: 'nowrap',
              width: {
                md: 198,
                lg: 208,
              },
            }}
          >
            {t('callsSchedule.bar.addBtn')}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
