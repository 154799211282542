import { useQuery } from 'react-query';
import { UseQueryResult } from 'react-query/types/react/types';
import { identityHttpClient } from '../../../common/utils';
import { useAuth } from '../../auth';

type ResponseType = {
  id: string;
  name: string;
  path: string;
}[];

export const useUserGroupsQuery = (userId: string): UseQueryResult<ResponseType, Error> => {
  const { authenticated } = useAuth();

  return useQuery<ResponseType, Error>(
    ['user-groups', userId],
    async () => {
      const response = await identityHttpClient.get<ResponseType>(`/Users/${userId}/groups`);
      return response.data;
    },
    {
      enabled: authenticated && !!userId,
      staleTime: 5000,
    },
  );
};
