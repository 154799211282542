import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
} from '@mui/material';
import { patterns } from '../../../../common/utils/validation';
import { BasicModal } from '../../../../components/basic-modal';
import { BasicSkeletons } from '../../../../components/basic-skeletons';
import { InputText } from '../../../../components/inputs';
import { useCreateUser, useScenarioGroupsQuery } from '../../../users/hooks';
import { useScenarioUsersQuery } from '../../hooks';

type Props = {
  handleModalClose: () => void;
  modalOpen: boolean;
};

type FormData = {
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  enable: boolean;
  group: string;
};

export const CreateUserModal: React.VFC<Props> = ({ handleModalClose, modalOpen }) => {
  const { t } = useTranslation();
  const { handleSubmit, control, reset } = useForm<FormData>();
  const { enqueueSnackbar } = useSnackbar();
  const { data: scenarioGroups, isError: isScenarioGroupsError } = useScenarioGroupsQuery();
  const { mutate, isLoading: isCreateUserLoading } = useCreateUser();
  const users = useScenarioUsersQuery();
  const desktopFormWidth = 490;

  const submit = React.useCallback(
    (formData: FormData) => {
      mutate(formData, {
        onSuccess: () => {
          handleModalClose();
          reset();
          users.refetch();
          enqueueSnackbar(t('users.snackbar.successfullyCreated'), { variant: 'success' });
        },
        onError: async () => {
          enqueueSnackbar(t('users.snackbar.failedToCreate'), { variant: 'error' });
        },
      });
    },
    [mutate, users],
  );

  const modalBody = (
    <Box
      sx={{
        width: {
          sm: desktopFormWidth,
        },
      }}
    >
      {isScenarioGroupsError || !scenarioGroups?.id ? (
        <p>{t('scenarioUsers.noScenarioGroupsError')}</p>
      ) : (
        <form onSubmit={handleSubmit(submit)}>
          {isCreateUserLoading ? (
            <BasicSkeletons number={5} />
          ) : (
            <>
              <Controller
                name="username"
                control={control}
                defaultValue=""
                rules={{ required: t('validation.required') as string }}
                render={({ field, fieldState: { error } }) => (
                  <InputText
                    fullWidth
                    margin={'dense'}
                    label={t('users.user.username')}
                    error={error}
                    name={field.name}
                    onChange={field.onChange}
                    value={field.value}
                  />
                )}
              />
              <Controller
                name="firstName"
                control={control}
                defaultValue=""
                rules={{ required: t('validation.required') as string }}
                render={({ field, fieldState: { error } }) => (
                  <InputText
                    fullWidth
                    margin={'dense'}
                    label={t('users.user.firstName')}
                    error={error}
                    name={field.name}
                    onChange={field.onChange}
                    value={field.value}
                  />
                )}
              />
              <Controller
                name="lastName"
                control={control}
                defaultValue=""
                rules={{ required: t('validation.required') as string }}
                render={({ field, fieldState: { error } }) => (
                  <InputText
                    fullWidth
                    margin={'dense'}
                    label={t('users.user.lastName')}
                    error={error}
                    name={field.name}
                    onChange={field.onChange}
                    value={field.value}
                  />
                )}
              />
              <Controller
                name="email"
                control={control}
                defaultValue=""
                rules={{
                  required: t('validation.required') as string,
                  pattern: {
                    value: patterns.email,
                    message: t('validation.invalidEmail'),
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <InputText
                    fullWidth
                    margin={'dense'}
                    label={t('users.user.email')}
                    error={error}
                    name={field.name}
                    onChange={field.onChange}
                    value={field.value}
                  />
                )}
              />
              {scenarioGroups && scenarioGroups.subGroups?.length ? (
                <FormControl
                  variant="outlined"
                  sx={{
                    width: '100%',
                    mt: 1,
                    mb: 0.5,
                  }}
                >
                  <InputLabel margin="dense" htmlFor="collection-select" id="collection-select-label">
                    {t('users.chooseRole')}
                  </InputLabel>
                  <Controller
                    name="group"
                    control={control}
                    defaultValue=""
                    rules={{ required: t('validation.required') as string }}
                    render={({ field, fieldState: { error } }) => (
                      <>
                        <Select
                          fullWidth
                          margin={'dense'}
                          id="role-select"
                          labelId="role-select-label"
                          label={t('users.chooseRole')}
                          name={field.name}
                          onChange={field.onChange}
                          value={field.value}
                          error={!!error}
                          sx={{
                            color: 'primary.main',
                          }}
                        >
                          <ListSubheader>{scenarioGroups.name}</ListSubheader>
                          {scenarioGroups.subGroups.map((group) => (
                            <MenuItem key={group.id} value={group.id}>
                              {group.name}
                            </MenuItem>
                          ))}
                        </Select>
                        {error && <FormHelperText sx={{ color: 'error.main' }}>{error.message}</FormHelperText>}
                      </>
                    )}
                  />
                </FormControl>
              ) : null}
              <FormControlLabel
                control={
                  <Controller
                    name="enable"
                    control={control}
                    defaultValue={true}
                    render={({ field }) => (
                      <Checkbox name={field.name} onChange={field.onChange} checked={field.value} />
                    )}
                  />
                }
                label={t('users.user.enabled') as string}
              />
            </>
          )}
          <FormGroup
            row
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={{
                marginTop: 3,
              }}
            >
              {t('apply')}
            </Button>
          </FormGroup>
        </form>
      )}
    </Box>
  );

  return (
    <BasicModal
      title={t('scenarioUsers.createNewScenarioUser')}
      modalBody={modalBody}
      handleModalClose={handleModalClose}
      modalOpen={modalOpen}
    />
  );
};
