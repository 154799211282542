import React from 'react';
import { Link } from 'react-router-dom';
import { Card, Typography } from '@mui/material';
import { routes } from '../../../routes';

type Props = {
  id: string;
  name: string;
};

export const ScenarioCard: React.FC<Props> = ({ id, name }) => {
  return (
    <Link to={routes.scenario.dashboard.link({ id: String(id) })}>
      <Card
        sx={{
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          position: 'relative',
          overflow: 'inherit',
          zIndex: 1,
          '& p': {
            zIndex: 2,
          },
          '&:after': {
            content: "''",
            zIndex: 0,
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'background.paper',
            borderRadius: 1,
          },
          '&:before': {
            content: "''",
            zIndex: -1,
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'secondary.main',
            borderRadius: 1,
            transition: 'transform 0.2s',
          },
          '&:hover': {
            '&:before': {
              transform: 'rotate(3deg)',
            },
          },
        }}
      >
        <Typography
          component="p"
          color="primary"
          sx={{
            fontWeight: 'bold',
            margin: '0.75rem',
          }}
        >
          {name}
        </Typography>
      </Card>
    </Link>
  );
};
