import { useMutation, UseMutationResult } from 'react-query';
import { MutationErrorType } from '../../../common/types';
import { identityHttpClient } from '../../../common/utils';
import { UserType } from '../types';

type Response = {
  user: UserType[];
};

type Body = {
  searchEmail: string;
  groupId: string;
};

export const useSearchUsersMutation = (): UseMutationResult<Response, MutationErrorType, Body> => {
  return useMutation(async (body) => {
    const response = await identityHttpClient.get(`/Groups/${body.groupId}/members/search?Email=${body.searchEmail}`);
    return response.data;
  });
};
