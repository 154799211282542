import React, { useContext } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Button, Stack, Typography } from '@mui/material';
import { BasicModal } from '../../../../components/basic-modal';
import { InputText } from '../../../../components/inputs';
import { LoaderComponent } from '../../../../components/loader-component';
import { useGroupsQuery, useScenarioGroupsQuery, useSearchUsersMutation } from '../../hooks';
import { UsersContext } from '../../provider/users-context.provider';
import { UserCard } from './user-card';

type Props = {
  handleModalClose: () => void;
  modalOpen: boolean;
  usersRefetch: () => void;
};

type FormData = {
  searchEmail: string;
};

export const AddExistingUserModal: React.VFC<Props> = ({ handleModalClose, modalOpen, usersRefetch }) => {
  const { t } = useTranslation();
  const { scenarioId } = useContext(UsersContext);
  const { handleSubmit, control } = useForm<FormData>();
  const { data: scenarioGroups, isError: isScenarioGroupsError } = useScenarioGroupsQuery(scenarioId.value);
  const { data: groups } = useGroupsQuery();
  const {
    mutate: mutateSearchUsers,
    data: searchedUsers,
    isLoading: isSearchUsersLoading,
    isError: isSearchUsersError,
  } = useSearchUsersMutation();
  const desktopFormWidth = 520;

  const search = async (formData: FormData) => {
    mutateSearchUsers({
      searchEmail: formData.searchEmail,
      groupId: groups?.id ?? '',
    });
  };

  const modalBody = (
    <Box
      sx={{
        width: {
          sm: desktopFormWidth,
        },
      }}
    >
      {isScenarioGroupsError || !scenarioGroups?.id ? (
        <p>{t('scenarioUsers.noScenarioGroupsError')}</p>
      ) : (
        <>
          <form onSubmit={handleSubmit(search)}>
            <Typography sx={{ mb: 1 }}>
              {t('users.user.scenario')}: {scenarioGroups?.name}
            </Typography>
            <Stack columnGap={1} direction={'row'} justifyContent={'space-between'} alignItems={'flex-start'}>
              <Controller
                name="searchEmail"
                control={control}
                defaultValue=""
                rules={{
                  required: t('validation.required') as string,
                  minLength: {
                    value: 5,
                    message: t('validation.minLength'),
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <InputText
                    fullWidth
                    margin={'dense'}
                    label={t('users.user.email')}
                    error={error}
                    name={field.name}
                    onChange={field.onChange}
                    value={field.value}
                  />
                )}
              />
              <Button
                disabled={isSearchUsersLoading}
                type="submit"
                variant="contained"
                color="primary"
                sx={{
                  marginTop: 1,
                  height: 56,
                  px: 3,
                }}
              >
                {t('search')}
              </Button>
            </Stack>
          </form>
          <Box>
            {isSearchUsersError ? <p>{t('users.error')}</p> : null}
            {isSearchUsersLoading ? <LoaderComponent /> : null}
            {searchedUsers ? (
              <>
                {searchedUsers.user.length === 0 ? (
                  <p>{t('users.noResults')}</p>
                ) : (
                  <>
                    {searchedUsers.user.map((user) => (
                      <UserCard key={user.id} user={user} scenarioGroups={scenarioGroups} usersRefetch={usersRefetch} />
                    ))}
                  </>
                )}
              </>
            ) : null}
          </Box>
        </>
      )}
    </Box>
  );

  return (
    <BasicModal
      title={t('users.addExistingUserToScenario')}
      modalBody={modalBody}
      handleModalClose={handleModalClose}
      modalOpen={modalOpen}
    />
  );
};
