import React from 'react';
import { SetValueType, useSetValue } from '../../../common/hooks';

export type UsersContextType = {
  scenarioId: SetValueType<string>;
  pageNumber: SetValueType<number>;
  tenantGroup: SetValueType<string>;
};

const initialData: UsersContextType = {
  scenarioId: { set: () => null, value: '' },
  pageNumber: { set: () => null, value: 1 },
  tenantGroup: { set: () => null, value: '' },
};

export const UsersContext = React.createContext<UsersContextType>(initialData);

export const UsersContextProvider: React.FC<React.PropsWithChildren> = (props) => {
  const scenarioId = useSetValue<string>(initialData.scenarioId.value);
  const pageNumber = useSetValue<number>(initialData.pageNumber.value);
  const tenantGroup = useSetValue<string>(initialData.tenantGroup.value);

  const value: UsersContextType = {
    scenarioId,
    pageNumber,
    tenantGroup,
  };

  return <UsersContext.Provider value={value}>{props.children}</UsersContext.Provider>;
};
