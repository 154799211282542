import { useMutation, UseMutationResult } from 'react-query';
import { MutationErrorType } from '../../../common/types';
import { identityHttpClient } from '../../../common/utils';

type Response = unknown;

export const useEnableDisableUser = (
  userId: string | undefined,
  isEnabled: boolean | undefined,
): UseMutationResult<Response, MutationErrorType> => {
  return useMutation(async () => {
    const response = await identityHttpClient.put(`/Users/${userId}/${isEnabled ? 'disable' : 'enable'}`);
    return response.data;
  });
};
