import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import LoadingButton from '@mui/lab/LoadingButton';
import { Card, Stack, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { useAddUserToGroup } from '../../hooks';
import { GroupType, UserType } from '../../types';

type Props = {
  user: UserType;
  scenarioGroups: GroupType | undefined;
  usersRefetch: () => void;
};

export const UserCard: React.VFC<Props> = ({ user, scenarioGroups, usersRefetch }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { mutate: mutateAddToGroup, isLoading: isAddUserLoading, isSuccess: isAddUserSuccess } = useAddUserToGroup();
  const [userGroup, setUserGroup] = React.useState<string | null>(null);

  const handleClick = () => {
    if (userGroup) {
      mutateAddToGroup(
        {
          groupId: userGroup,
          userId: user.id,
        },
        {
          onSuccess: async () => {
            usersRefetch();
            enqueueSnackbar(t('users.snackbar.successfullyEditedGroup'), { variant: 'success' });
          },
          onError: async () => {
            enqueueSnackbar(t('users.snackbar.failedToEditGroup'), { variant: 'error' });
          },
        },
      );
    }
  };

  const handleChange = (event: React.MouseEvent<HTMLElement>, newUserGroup: string) => {
    setUserGroup(newUserGroup);
  };

  return (
    <>
      <Card
        sx={{
          p: 2,
          mt: 2,
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'space-between',
          justifyContent: 'center',
          position: 'relative',
          overflow: 'inherit',
        }}
      >
        <Stack rowGap={1}>
          <Typography
            component="p"
            color="primary"
            sx={{
              fontWeight: 'bold',
            }}
          >
            {user.email} ({user.username})
          </Typography>
          <Typography component="p" color="primary">
            {user.firstName} {user.lastName}
          </Typography>
          {scenarioGroups && scenarioGroups.subGroups?.length ? (
            <ToggleButtonGroup fullWidth size={'small'} value={userGroup} exclusive onChange={handleChange}>
              {scenarioGroups.subGroups.map((group) => (
                <ToggleButton key={group.id} value={group.id}>
                  {group.name}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          ) : null}
          <LoadingButton
            disabled={isAddUserSuccess || !userGroup}
            loading={isAddUserLoading}
            color="primary"
            variant="contained"
            type="button"
            onClick={handleClick}
          >
            {isAddUserSuccess ? t('added') : t('add')}
          </LoadingButton>
        </Stack>
      </Card>
    </>
  );
};
