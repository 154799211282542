import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { Heading } from '../../components/heading';
import { UsersBar } from './components/users-bar';
import { UsersTable } from './components/users-table';
import { UsersContextProvider } from './provider/users-context.provider';

export const UsersPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <UsersContextProvider>
      <Box sx={{ flexGrow: 1 }}>
        <Heading name={t('users.title')}>
          <UsersBar />
        </Heading>
        <UsersTable />
      </Box>
    </UsersContextProvider>
  );
};
