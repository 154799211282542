import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Box, Button, Typography } from '@mui/material';
import { BasicModal } from '../../../../components/basic-modal';
import { routes } from '../../../../routes';
import { useDeleteUser } from '../../hooks';

type Props = {
  handleModalClose: () => void;
  modalOpen: boolean;
  userId?: string;
};

export const ConfirmUserDeleteModal: React.FC<Props> = ({ handleModalClose, modalOpen, userId }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { mutate } = useDeleteUser(userId);

  const remove = React.useCallback(() => {
    mutate(
      {},
      {
        onSuccess: () => {
          handleModalClose();
          navigate(routes.users.path);
          enqueueSnackbar(t('users.snackbar.successfullyDeleted'), { variant: 'success' });
        },
        onError: async () => {
          handleModalClose();
          enqueueSnackbar(t('users.snackbar.failedToDelete'), { variant: 'error' });
        },
      },
    );
  }, [mutate]);

  const ModalBodyComponent = (
    <Box>
      <>
        <Typography variant="body2" sx={{ marginBottom: 3 }}>
          {t('users.deleteUserMessage')}
        </Typography>
      </>
      <Box display="flex" justifyContent="flex-end">
        <Button color="primary" variant="outlined" onClick={handleModalClose} sx={{ marginRight: 1 }}>
          {t('cancel')}
        </Button>
        <Button type="submit" color="error" variant="contained" onClick={remove}>
          {t('delete')}
        </Button>
      </Box>
    </Box>
  );

  return (
    <BasicModal
      modalOpen={modalOpen}
      handleModalClose={handleModalClose}
      modalBody={ModalBodyComponent}
      title={t('users.deleteUserTitle')}
    />
  );
};
