import React from 'react';
import { useTranslation } from 'react-i18next';
import { PlayCircleOutline } from '@mui/icons-material';
import { Button } from '@mui/material';
import { useDeviceQuery, useModal } from '../../../../common/hooks';
import { StartCallingModal } from './start-calling-modal';

export const StartCallingButton: React.FC<{ disabled?: boolean }> = ({ disabled }) => {
  const { t } = useTranslation();
  const { isMobile, isDesktop } = useDeviceQuery();
  const startCallingModal = useModal();

  const handleStartCallingModal = () => {
    startCallingModal.open();
  };

  return (
    <>
      {isMobile ? (
        <Button
          disabled={disabled}
          color="primary"
          variant="contained"
          startIcon={<PlayCircleOutline />}
          onClick={handleStartCallingModal}
          sx={{
            height: (theme) => theme.spacing(6),
            minWidth: 48,
            display: 'flex',
            alignItems: 'center',
            columnGap: (theme) => theme.spacing(2),
            whiteSpace: 'nowrap',
            my: 0,
            mx: 2,
            '& .MuiButton-startIcon': {
              margin: 0,
            },
          }}
        />
      ) : (
        <Button
          disabled={disabled}
          color="primary"
          variant="contained"
          startIcon={<PlayCircleOutline />}
          onClick={handleStartCallingModal}
          sx={{
            height: (theme) => theme.spacing(6),
            minWidth: 48,
            display: 'flex',
            alignItems: 'center',
            columnGap: (theme) => theme.spacing(2),
            whiteSpace: 'nowrap',
            my: 0,
            mx: 2,
          }}
        >
          {`${t('callsSchedule.collection.start')} ${isDesktop ? t('callsSchedule.collection.calling') : ''} `}
        </Button>
      )}
      <StartCallingModal modalOpen={startCallingModal.isOpen} handleModalClose={startCallingModal.close} />
    </>
  );
};
