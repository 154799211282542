import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { BasicLayout, DashboardLayout } from '../layouts';
import { AddCollectionPage, CallsSchedulePage } from '../modules/calls-schedule';
import { DashboardPage } from '../modules/dashboard';
import { HistoryPage } from '../modules/history';
import { ReportsPage } from '../modules/reports';
import { ScenarioConfiguratorPage } from '../modules/scenario-configurator';
import { ScenarioUsersPage } from '../modules/scenario-users';
import { ScenariosPage } from '../modules/scenarios';
import { UserPage, UsersPage } from '../modules/users';
import { routes } from './utils';
import { CallsPage } from '../modules/calls';
import { RecordingDownloadPage } from '../modules/reports';

export const RootRouting: React.FC = () => (
  <Routes>
    <Route path={routes.home.path} element={<BasicLayout />}>
      <Route path={`${routes.home.path}`} element={<ScenariosPage />} />
      <Route path={`${routes.users.path}`} element={<UsersPage />} />
      <Route path={`${routes.user.path}`} element={<UserPage />} />
    </Route>
    <Route path={routes.scenario.dashboard.path} element={<DashboardLayout />}>
      <Route path={routes.scenario.dashboard.path} element={<DashboardPage />} />
      <Route path={routes.scenario.history.path} element={<HistoryPage />} />
      <Route path={routes.scenario.reports.path} element={<ReportsPage />} />
      <Route path={routes.scenario.call.path} element={<CallsPage />} />
      <Route path={routes.scenario.callsSchedule.collectionList.path}>
        <Route path={routes.scenario.callsSchedule.collectionList.path} element={<CallsSchedulePage />} />
        <Route path={routes.scenario.callsSchedule.addCollection.path} element={<AddCollectionPage />} />
      </Route>
      <Route path={routes.scenario.scenarioConfigurator.path} element={<ScenarioConfiguratorPage />} />
      <Route path={routes.scenario.users.path} element={<ScenarioUsersPage />} />
    </Route>
    <Route path={routes.recordingDownload.path} element={<RecordingDownloadPage />} />
  </Routes>
);
