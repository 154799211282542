import { useQuery } from 'react-query';
import { UseQueryResult } from 'react-query/types/react/types';
import { identityHttpClient } from '../../../common/utils';
import { useAuth } from '../../auth';
import { useScenario } from '../../scenarios/hooks';
import { GroupType } from '../types';

type ResponseType = GroupType;

export const useScenarioGroupsQuery = (scenarioId?: string): UseQueryResult<ResponseType, Error> => {
  const { authenticated, tokenParsed } = useAuth();
  const { id: scenarioIdHook } = useScenario();
  const scenario = scenarioIdHook ? scenarioIdHook : scenarioId;
  const tenantId = tokenParsed?.tenant_id ? tokenParsed?.tenant_id[0] : '';

  return useQuery<ResponseType, Error>(
    ['scenario-groups', scenario],
    async () => {
      const response = await identityHttpClient.get<ResponseType>(`/Groups/scenario/${scenario}/${tenantId}`);
      return response.data;
    },
    {
      enabled: authenticated && Boolean(scenario) && scenario !== 'all',
      staleTime: 5000,
    },
  );
};
