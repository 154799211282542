import React from 'react';
import { Outlet } from 'react-router-dom';
import { Navbar } from '../modules/navbar';
import { SideNavigation } from '../modules/side-navigation';

export const DashboardLayout: React.FC = () => {
  return (
    <>
      <Navbar />
      <SideNavigation>
        <Outlet />
      </SideNavigation>
    </>
  );
};
