import { useContext } from 'react';
import { addDays, differenceInDays, endOfDay, format, startOfDay } from 'date-fns';
import { MAX_DATE_RANGE } from '../../../common/constants';
import { FilterType } from '../../../common/enums/filters.enum';
import { ReportsContext } from '../provider/reports-context.provider';

export const useExportReportDateQueryFilter = (): string | undefined => {
  const { dateRange, dateColumnName } = useContext(ReportsContext);

  let dateQueryFilter = '';
  let dateFrom = dateRange.value.from || new Date();
  const dateTo = dateRange.value.to || new Date();
  const differenceBetweenDates = differenceInDays(dateTo, dateFrom);

  if (differenceBetweenDates > MAX_DATE_RANGE) {
    dateFrom = addDays(dateFrom, differenceBetweenDates - (MAX_DATE_RANGE - 1));
  }

  dateQueryFilter += `&filters[0].type=${FilterType.DatePickerExportRange}&filters[0].columnName=${dateColumnName.value}`;
  dateQueryFilter += `&filters[0].value.from=${format(startOfDay(dateFrom), 'yyyy-MM-dd HH:mm:ss')}`;
  dateQueryFilter += `&filters[0].value.to=${format(endOfDay(dateTo), 'yyyy-MM-dd HH:mm:ss')}`;

  return dateQueryFilter;
};
