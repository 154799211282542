import { PaletteOptions } from '@mui/material/styles/createPalette';

export const paletteOptions: PaletteOptions = {
  primary: {
    main: '#302f5b',
    dark: '#1c1b40',
    light: '#514e8d',
    contrastText: '#FFFFFF',
  },
  secondary: {
    main: '#09d9d7',
  },
  background: {
    default: '#EEF0F7',
    paper: '#FFFFFF',
  },
  text: {
    primary: '#011116',
    secondary: '#7e7e90',
  },
};
