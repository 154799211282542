import React from 'react';
import { useTranslation } from 'react-i18next';
import GroupsIcon from '@mui/icons-material/Groups';
import { Box, Divider, Typography } from '@mui/material';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import { BasicSkeletons } from '../../../../components/basic-skeletons';
import { useGroupsQuery, useUserGroupsQuery } from '../../hooks';
import { Scenario } from './index';

type Props = {
  userId: string;
};

export const ScenarioGroupSelect: React.VFC<Props> = ({ userId }) => {
  const { t } = useTranslation();
  const { data: groups, isLoading: isGroupsLoading } = useGroupsQuery();
  const { data: userGroups, isLoading: isUserGroupsLoading, refetch } = useUserGroupsQuery(userId);
  const mappedUserGroups = userGroups?.map((userGroup) => userGroup.id);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        p: 4,
        boxShadow: '0px 0px 25px -10px rgba(66, 68, 90, 0.3)',
        borderRadius: '15px',
        width: {
          xs: '100%',
          lg: 600,
        },
      }}
    >
      <GroupsIcon fontSize="large" color="primary" />
      <Typography variant="h5">{t('users.user.scenarioGroups')}</Typography>
      <Divider
        sx={{
          margin: '20px 0',
          width: '100%',
        }}
      />
      {isGroupsLoading || isUserGroupsLoading ? (
        <Box
          sx={{
            display: 'flex',
            padding: '10px',
            justifyContent: 'space-evenly',
            flexDirection: 'column',
            width: 1,
          }}
        >
          <BasicSkeletons number={4} height={70} width={'100%'} />
        </Box>
      ) : (
        <List
          sx={{ width: '100%', maxWidth: 450, background: 'background.paper' }}
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader
              component="div"
              id="groups-subheader"
              sx={{
                textAlign: 'center',
                lineHeight: 'normal',
                marginBottom: 3,
              }}
            >
              {t('users.user.scenarios.showGroups')}
            </ListSubheader>
          }
        >
          {groups?.subGroups.map((scenario) => (
            <Box key={scenario.id}>
              {scenario.subGroups.length ? (
                <Scenario refetch={refetch} userGroups={mappedUserGroups} scenario={scenario} userId={userId} />
              ) : null}
            </Box>
          ))}
        </List>
      )}
    </Box>
  );
};
