import React from 'react';
import { SetValueType, useSetValue } from '../../../common/hooks';

export type ScenarioUsersContextType = {
  pageNumber: SetValueType<number>;
};

const initialData: ScenarioUsersContextType = {
  pageNumber: { set: () => null, value: 1 },
};

export const ScenarioUsersContext = React.createContext<ScenarioUsersContextType>(initialData);

export const ScenarioUsersContextProvider: React.FC<React.PropsWithChildren> = (props) => {
  const pageNumber = useSetValue<number>(initialData.pageNumber.value);

  const value: ScenarioUsersContextType = {
    pageNumber,
  };

  return <ScenarioUsersContext.Provider value={value}>{props.children}</ScenarioUsersContext.Provider>;
};
