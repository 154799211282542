import React from 'react';
import { useTranslation } from 'react-i18next';
import { PhoneForwardedOutlined } from '@mui/icons-material';
import { Button, Tooltip } from '@mui/material';
import { useScenario } from '../../../scenarios/hooks';
import { BatchType } from '../../types';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useStartImmediateCallingsInBatch } from '../../hooks/use-start-immediate-callings-in-batch';

type Props = {
  batch: BatchType;
  disabled: boolean;
};

export const StartImmediateCallingsInBatch: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { id } = useScenario();
  const { mutate } = useStartImmediateCallingsInBatch();

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    mutate(
      {
        batchId: props.batch.id,
        scenarioId: id,
      },
      {
        onSuccess: () => {
          handleClose();
        },
      },
    );
  };

  return (
    <>
      <Tooltip title={t('callsSchedule.collection.immediateCallings.tooltip')} arrow enterDelay={500} leaveDelay={200}>
        <Button
          disabled={props.disabled}
          onClick={handleClickOpen}
          variant="outlined"
          sx={{
            width: 48,
            height: 48,
            minWidth: 48,
            '& .MuiButton-startIcon': {
              margin: 0,
            },
            marginRight: 2,
          }}
        >
          <PhoneForwardedOutlined />
        </Button>
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{ fontWeight: 900 }}>
          {t('callsSchedule.collection.immediateCallings.title')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('callsSchedule.collection.immediateCallings.information', {
              immediateCallingsCount: props.batch.recordsAvailableToImmediateCallingCount,
              recordsCount: props.batch.recordsCount,
            })}
            <br />
            <br />
            {t('callsSchedule.collection.immediateCallings.description')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <br />
          <Button onClick={handleClose}>{t('callsSchedule.collection.immediateCallings.cancel')}</Button>
          <Button onClick={handleConfirm} autoFocus>
            {t('callsSchedule.collection.immediateCallings.confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
