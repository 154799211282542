import React from 'react';
import { Box } from '@mui/material';
import { UserProfile } from './components/user-profile';

export const UserPage: React.FC = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <UserProfile />
    </Box>
  );
};
