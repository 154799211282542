import React, { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useSetValue } from '../../../common/hooks';
import { useScenariosQuery } from '../hooks';

type ScenarioContextType = {
  id: string;
  name: string;
};

const initialData: ScenarioContextType = {
  name: '',
  id: '',
};

export const ScenarioContext = React.createContext<ScenarioContextType>(initialData);

export const ScenarioContextProvider: React.FC<React.PropsWithChildren> = (props) => {
  const scenarioName = useSetValue<string>(initialData.name);

  const location = useLocation();
  const { id } = useParams();
  const { data, isFetched } = useScenariosQuery();

  const scenarioId = id || location.pathname.split('/scenario/')?.[1]?.split('/')?.[0];

  useEffect(() => {
    if (!isFetched || !data || !scenarioId) return;
    const scenario = data.scenarioCollection.find((x) => x.scenarioId === scenarioId);
    scenarioName.set(scenario?.scenarioName ?? '');
  }, [isFetched, data, scenarioId]);

  const value: ScenarioContextType = {
    name: scenarioName.value,
    id: scenarioId,
  };

  return <ScenarioContext.Provider value={value}>{props.children}</ScenarioContext.Provider>;
};
