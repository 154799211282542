import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import MenuIcon from '@mui/icons-material/Menu';
import { Box, Button, FormControlLabel, IconButton, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import { useModal } from '../../../../common/hooks';
import { Heading } from '../../../../components/heading';
import { useEnableDisableUser, useUserQuery, useUserResetPassword } from '../../hooks';
import { ConfirmUserDeleteModal } from '../confirm-user-delete-modal';
import { ScenarioGroupSelect } from '../scenario-group-select';
import { UserProfileForm } from '../user-profile-form/';
import { HeadingSkeleton } from './heading-skeleton';
import { Switch } from './switch';

export const UserProfile: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { data, refetch, isLoading } = useUserQuery(id);
  const { enqueueSnackbar } = useSnackbar();
  const confirmUserDeleteModal = useModal();
  const { mutate: mutateEnableDisableUser } = useEnableDisableUser(id, data?.user.enabled);
  const { mutate: mutateUserResetPassword } = useUserResetPassword(data?.user.id ?? '');

  const [userAnchorEl, setUserAnchorEl] = React.useState<null | HTMLElement>(null);
  const isUserMenuOpen = Boolean(userAnchorEl);
  const handleUserAnchorClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setUserAnchorEl(event.currentTarget);
  };
  const handleUserMenuClose = () => {
    setUserAnchorEl(null);
  };

  const toggleEnableDisableUser = React.useCallback(() => {
    mutateEnableDisableUser(
      {},
      {
        onSuccess: () => {
          refetch();
          enqueueSnackbar(t('users.snackbar.successfullyEnabledDisabled'), { variant: 'success' });
        },
        onError: async () => {
          enqueueSnackbar(t('users.snackbar.failedToEnableDisable'), { variant: 'error' });
        },
      },
    );
  }, [mutateEnableDisableUser]);

  const handleUserResetPassword = React.useCallback(() => {
    mutateUserResetPassword(
      {},
      {
        onSuccess: () => {
          enqueueSnackbar(t('users.snackbar.resetPasswordRequestSuccess'), { variant: 'success' });
        },
        onError: async () => {
          enqueueSnackbar(t('users.snackbar.resetPasswordRequestError'), { variant: 'error' });
        },
      },
    );
  }, [mutateUserResetPassword]);

  return (
    <Box sx={{ pb: 8 }}>
      {isLoading ? (
        <HeadingSkeleton />
      ) : (
        <Heading
          name={t('users.user.profile')}
          sx={{
            position: 'relative',
            justifyContent: {
              xs: 'flex-start',
              md: 'space-between',
            },
          }}
          typoSx={{
            fontWeight: 500,
            mr: {
              xs: 0,
              sm: 1,
            },
            width: {
              xs: '100%',
              sm: 'auto',
            },
          }}
        >
          <Box
            sx={{
              display: {
                xs: 'block',
                md: 'none',
              },
              position: 'absolute',
              top: 13,
              right: 0,
            }}
          >
            <IconButton aria-label="delete" onClick={handleUserAnchorClick}>
              <MenuIcon />
            </IconButton>
            <Menu anchorEl={userAnchorEl} open={isUserMenuOpen} onClose={handleUserMenuClose}>
              <MenuItem
                onClick={() => {
                  confirmUserDeleteModal.open();
                  handleUserMenuClose();
                }}
              >
                {t('delete')}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleUserResetPassword();
                  handleUserMenuClose();
                }}
              >
                {t('users.resetPassword')}
              </MenuItem>
            </Menu>
          </Box>
          <Box
            sx={{
              display: 'flex',
              width: 1,
              flexWrap: 'wrap',
              justifyContent: {
                xs: 'space-between',
                sm: 'flex-start',
              },
              marginRight: {
                xs: 0,
                sm: 5,
                md: 'auto',
              },
            }}
          >
            <Typography
              component={'h2'}
              sx={{
                whiteSpace: 'nowrap',
                display: 'flex',
                alignItems: 'center',
                fontSize: '1.5rem',
                fontWeight: 700,
                height: {
                  md: 75,
                },
                lineHeight: '1.89rem',
                width: 'auto',
                mr: {
                  xs: 0,
                  sm: 2,
                },
              }}
            >
              {data?.user?.username}
            </Typography>
            <Tooltip placement={'bottom'} title={`${t('users.user.clickToChangeStatus')}`}>
              <FormControlLabel
                control={<Switch />}
                checked={data?.user.enabled}
                label={data?.user.enabled ? (t('users.user.active') as string) : (t('users.user.inactive') as string)}
                onChange={toggleEnableDisableUser}
                sx={{
                  position: 'relative',
                  ml: 0,
                  mr: 1,
                  '.MuiFormControlLabel-label': {
                    fontSize: '0.8rem',
                    fontWeight: 'bold',
                    position: 'absolute',
                    margin: 0,
                    lineHeight: 0,
                    color: 'white',
                    top: '50%',
                    textTransform: 'uppercase',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                  },
                }}
              />
            </Tooltip>
          </Box>
          <Box
            sx={{
              display: {
                xs: 'none',
                md: 'flex',
              },
              alignItems: 'center',
              flexWrap: 'wrap',
              rowGap: 1,
              marginLeft: {
                sm: '20px',
                md: 0,
                lg: 0,
                xl: 0,
              },
              width: 'auto',
            }}
          >
            <Box sx={{ display: 'flex' }}>
              <Button variant="outlined" onClick={confirmUserDeleteModal.open}>
                {t('delete')}
              </Button>
              <Button
                variant="outlined"
                onClick={handleUserResetPassword}
                sx={{
                  ml: 1,
                  whiteSpace: 'nowrap',
                }}
              >
                {t('users.resetPassword')}
              </Button>
            </Box>
          </Box>
          <ConfirmUserDeleteModal
            handleModalClose={confirmUserDeleteModal.close}
            modalOpen={confirmUserDeleteModal.isOpen}
            userId={data?.user.id}
          />
        </Heading>
      )}
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          columnGap: 3,
          rowGap: 5,
          padding: 3,
          justifyContent: 'space-evenly',
          alignItems: 'flex-start',
        }}
      >
        <UserProfileForm />
        <ScenarioGroupSelect userId={data?.user.id ?? ''} />
      </Box>
    </Box>
  );
};
