import React from 'react';
import { useTranslation } from 'react-i18next';
import { Heading } from '../../components/heading';
import { UsersBar } from './components/users-bar';
import { UsersTable } from './components/users-table';
import { ScenarioUsersContextProvider } from './provider/scenario-users-context.provider';

export const ScenarioUsersPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ScenarioUsersContextProvider>
      <Heading name={t('scenarioUsers.heading')}>
        <UsersBar />
      </Heading>
      <UsersTable />
    </ScenarioUsersContextProvider>
  );
};
