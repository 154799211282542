import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Container, Grid, List, Theme, Typography, useMediaQuery } from '@mui/material';
import { LoaderComponent } from '../../../../components/loader-component';
import { useBatchesQuery } from '../../hooks';
import { BatchContextProvider } from '../../provider';
import { BatchItem } from './index';

export const BatchList: React.FC = () => {
  const { t } = useTranslation();
  const { data, isFetched, isLoading } = useBatchesQuery();
  const disableGutters = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  return (
    <Container
      maxWidth="xxl"
      disableGutters={disableGutters}
      sx={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      {(!isFetched || data?.batches.length === 0) && !isLoading ? (
        <Grid container justifyContent="center">
          <Grid
            item
            sx={{
              width: '100%',
              py: 3,
              px: {
                xs: 0,
                md: 3,
              },
            }}
          >
            <Card
              sx={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
                padding: 4,
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  textAlign: 'center',
                }}
              >
                {!isFetched ? t('callsSchedule.list.selectCollections') : t('callsSchedule.list.noCollections')}
              </Typography>
            </Card>
          </Grid>
        </Grid>
      ) : (
        <List
          sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            rowGap: (theme) => theme.spacing(1),
            backgroundColor: 'common.white',
            width: 0,
          }}
        >
          {isLoading ? (
            <LoaderComponent />
          ) : (
            data?.batches.map((batch) => (
              <BatchContextProvider key={batch.id}>
                <BatchItem batch={batch} />
              </BatchContextProvider>
            ))
          )}
        </List>
      )}
    </Container>
  );
};
