import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { Box, Button, FormGroup, Stack, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { BasicModal } from '../../../../components/basic-modal';
import { BasicSkeletons } from '../../../../components/basic-skeletons';
import { InputText } from '../../../../components/inputs';
import { useChangeUserGroup, useScenarioGroupsQuery, useUpdateUserData } from '../../../users/hooks';
import { useScenarioUsersQuery } from '../../hooks';
import { UserType } from '../../types';

type Props = {
  handleModalClose: () => void;
  modalOpen: boolean;
  user: UserType;
};

type FormData = {
  username: string;
  firstName: string;
  lastName: string;
  email: string;
};

export const EditUserModal: React.VFC<Props> = ({ handleModalClose, modalOpen, user }) => {
  const defaultValues: FormData = {
    username: user.username ?? '',
    firstName: user.firstName ?? '',
    lastName: user.lastName ?? '',
    email: user.email ?? '',
  };

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { handleSubmit, control, reset } = useForm({
    defaultValues,
  });
  const { data: scenarioGroups, isError: isScenarioGroupsError } = useScenarioGroupsQuery();
  const { mutate: mutateEditUser, isLoading: isEditUserLoading } = useUpdateUserData(user.id);
  const { mutate: mutateChangeUserGroup, isLoading: isChangeUserGroupLoading } = useChangeUserGroup();
  const users = useScenarioUsersQuery();
  const desktopFormWidth = 490;

  const [userGroup, setUserGroup] = React.useState(user.groupId);

  React.useEffect(() => {
    reset(user);
    setUserGroup(user.groupId);
  }, [user]);

  const submit = React.useCallback(
    (formData: FormData) => {
      mutateEditUser(formData, {
        onSuccess: () => {
          handleModalClose();
          users.refetch();
          enqueueSnackbar(t('users.snackbar.successfullyUpdated'), { variant: 'success' });
        },
        onError: async () => {
          enqueueSnackbar(t('users.snackbar.failedToUpdate'), { variant: 'error' });
        },
      });
    },
    [mutateEditUser, users],
  );

  const handleChange = (event: React.MouseEvent<HTMLElement>, newUserGroup: string) => {
    mutateChangeUserGroup(
      {
        userId: user.id,
        groupFromId: user.groupId,
        groupToId: newUserGroup,
      },
      {
        onSuccess: () => {
          handleModalClose();
          users.refetch();
          enqueueSnackbar(t('users.snackbar.successfullyEditedGroup'), { variant: 'success' });
        },
        onError: async () => {
          enqueueSnackbar(t('users.snackbar.failedToEditGroup'), { variant: 'error' });
        },
      },
    );
  };

  const modalBody = (
    <Box
      sx={{
        width: {
          sm: desktopFormWidth,
        },
      }}
    >
      {isScenarioGroupsError || !scenarioGroups?.id ? (
        <p>{t('scenarioUsers.noScenarioGroupsError')}</p>
      ) : (
        <Stack rowGap={2}>
          {isEditUserLoading || isChangeUserGroupLoading ? (
            <Box sx={{ width: '100%' }}>
              <BasicSkeletons number={6} width={'100%'} />
            </Box>
          ) : (
            <>
              <form>
                <Typography variant="h6" sx={{ mb: 1 }}>
                  {t('users.user.userData')}
                </Typography>
                <Controller
                  name="email"
                  control={control}
                  rules={{ required: t('validation.required') as string }}
                  render={({ field, fieldState: { error } }) => (
                    <InputText
                      disabled
                      fullWidth
                      margin={'dense'}
                      label={t('users.user.email')}
                      error={error}
                      name={field.name}
                      onChange={field.onChange}
                      value={field.value}
                    />
                  )}
                />
                <Controller
                  name="username"
                  control={control}
                  rules={{ required: t('validation.required') as string }}
                  render={({ field, fieldState: { error } }) => (
                    <InputText
                      disabled
                      fullWidth
                      margin={'dense'}
                      label={t('users.user.username')}
                      error={error}
                      name={field.name}
                      onChange={field.onChange}
                      value={field.value}
                    />
                  )}
                />
                <Controller
                  name="firstName"
                  control={control}
                  rules={{ required: t('validation.required') as string }}
                  render={({ field, fieldState: { error } }) => (
                    <InputText
                      fullWidth
                      margin={'dense'}
                      label={t('users.user.firstName')}
                      error={error}
                      name={field.name}
                      onChange={field.onChange}
                      value={field.value}
                    />
                  )}
                />
                <Controller
                  name="lastName"
                  control={control}
                  rules={{ required: t('validation.required') as string }}
                  render={({ field, fieldState: { error } }) => (
                    <InputText
                      fullWidth
                      margin={'dense'}
                      label={t('users.user.lastName')}
                      error={error}
                      name={field.name}
                      onChange={field.onChange}
                      value={field.value}
                    />
                  )}
                />
                <FormGroup
                  row
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit(submit)}
                    sx={{
                      width: {
                        xs: '100%',
                        sm: 'auto',
                      },
                      marginTop: 1,
                      height: 48,
                    }}
                  >
                    {t('edit')}
                  </Button>
                </FormGroup>
              </form>
              <Box>
                <Typography variant="h6" sx={{ mb: 1 }}>
                  {t('users.user.group')}
                </Typography>
                {scenarioGroups && scenarioGroups.subGroups?.length ? (
                  <ToggleButtonGroup fullWidth value={userGroup} exclusive onChange={handleChange}>
                    {scenarioGroups.subGroups.map((group) => (
                      <ToggleButton key={group.id} value={group.id}>
                        {group.name}
                      </ToggleButton>
                    ))}
                  </ToggleButtonGroup>
                ) : null}
              </Box>
            </>
          )}
        </Stack>
      )}
    </Box>
  );

  return (
    <BasicModal
      title={t('scenarioUsers.editUser')}
      modalBody={modalBody}
      handleModalClose={handleModalClose}
      modalOpen={modalOpen}
    />
  );
};
