import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CheckCircleOutlineOutlined,
  DoNotDisturbAltOutlined,
  ErrorOutlineOutlined,
  ExpandMore,
  HelpOutline,
  HourglassEmptyOutlined,
  InputOutlined,
  SaveAsOutlined,
  ScreenRotationAltOutlined,
} from '@mui/icons-material';
import { Button, Grid, ListItem, Typography } from '@mui/material';
import { useDeviceQuery } from '../../../../common/hooks';
import { displayDateWithHour } from '../../../../common/utils';
import { BatchContext } from '../../provider';
import { BatchType } from '../../types';
import { BatchTable } from './batch-table';
import { EditBatchButton } from './edit-batch-button';
import { StartCallingButton } from './start-calling-button';
import { StopCallingButton } from './stop-calling-button';
import { StartImmediateCallingsInBatch } from './start-immediate-callings-in-batch';
import { BatchHistory } from './batch-history';
import { TimeZoneContext } from '../../../scenarios/provider/timezone-context.provider';

type Props = {
  batch: BatchType;
};

export const BatchItem: React.FC<Props> = ({ batch }) => {
  const { t } = useTranslation();
  const [isTableVisible, setIsTableVisible] = useState(false);
  const { isDesktop, isMobile } = useDeviceQuery();
  const { batchId, batchName, callingInProgress } = useContext(BatchContext);
  const { timezone } = useContext(TimeZoneContext);
  useEffect(() => {
    batchId.set(batch.id);
    batchName.set(batch.batchName);
    if (batch.status === 'InProgress') {
      callingInProgress.set(true);
    }
  }, []);

  return (
    <ListItem
      key={batch.id}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        px: {
          xs: 0,
          md: 3,
        },
      }}
    >
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        wrap="nowrap"
        sx={{
          height: (theme) => theme.spacing(12),
          backgroundColor: 'background.default',
          borderRadius: 1,
          padding: 3,
          borderBottomRightRadius: isTableVisible ? 0 : 1,
          borderBottomLeftRadius: isTableVisible ? 0 : 1,
        }}
      >
        <Grid
          item
          md={isDesktop ? 3 : 12}
          zeroMinWidth
          sx={{
            display: 'flex',
            alignItems: 'center',
            columnGap: (theme) => theme.spacing(isMobile ? 2 : 3),
            marginRight: 0,
          }}
        >
          <Button
            color="primary"
            variant="contained"
            startIcon={<ExpandMore />}
            onClick={() => setIsTableVisible((prev) => !prev)}
            sx={{
              width: 32,
              height: 32,
              minHeight: 32,
              minWidth: 32,
              borderRadius: 50,
              '& .MuiButton-startIcon': {
                margin: 0,
                transform: isTableVisible ? 'rotate(0.5turn)' : undefined,
              },
            }}
          />
          <Typography
            variant="h5"
            noWrap
            title={batch.batchName}
            sx={{
              fontWeight: 'bold',
              fontSize: 18,
            }}
          >
            {batch.batchName}
            {batch.savedOnDate && (
              <Typography
                component={'span'}
                sx={{
                  color: 'text.secondary',
                  display: 'block',
                  fontSize: 14,
                  fontWeight: 400,
                  marginTop: 1,
                }}
              >
                {displayDateWithHour(batch.savedOnDate, timezone)}
              </Typography>
            )}
          </Typography>
        </Grid>
        <Grid
          item
          md={18}
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            paddingLeft: 1,
          }}
        >
          {isDesktop && (
            <Grid container spacing={4} justifyContent="space-around" wrap="nowrap">
              <Grid
                item
                zeroMinWidth
                sx={{
                  minWidth: 134,
                  //      width: 350,
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  width: '35%',
                  overflow: 'hidden',
                  rowGap: (theme) => theme.spacing(2),
                }}
              >
                <Typography
                  variant="body2"
                  title={t('callsSchedule.collection.status')}
                  noWrap
                  sx={{
                    margin: 0,
                    fontWeight: 'bold',
                    overflow: 'hidden',
                  }}
                >
                  {t('callsSchedule.collection.status')}
                </Typography>
                <Typography
                  noWrap
                  component={'p'}
                  sx={{
                    margin: 0,
                    display: 'flex',
                    alignItems: 'center',
                    overflow: 'hidden',
                  }}
                >
                  {batch.status == 'Unknown' ? (
                    <HelpOutline sx={{ marginRight: '4%', fontSize: '110%', color: '#F26F61' }} />
                  ) : null}

                  {batch.status == 'Drafted' ? (
                    <SaveAsOutlined sx={{ marginRight: '4%', fontSize: '110%', color: '#400561' }} />
                  ) : null}

                  {batch.status == 'Saved' ? (
                    <HourglassEmptyOutlined sx={{ marginRight: '4%', fontSize: '110%', color: '#666666' }} />
                  ) : null}

                  {batch.status == 'Started' ? (
                    <InputOutlined sx={{ marginRight: '4%', fontSize: '110%', color: '#40a6ce' }} />
                  ) : null}

                  {batch.status == 'Processing' ? (
                    <ScreenRotationAltOutlined sx={{ marginRight: '4%', fontSize: '110%', color: '#3C45BB' }} />
                  ) : null}

                  {batch.status == 'Completed' ? (
                    <CheckCircleOutlineOutlined sx={{ marginRight: '4%', fontSize: '110%', color: '#339900' }} />
                  ) : null}

                  {batch.status == 'Canceled' ? (
                    <DoNotDisturbAltOutlined sx={{ marginRight: '4%', fontSize: '110%', color: '#ec942c' }} />
                  ) : null}

                  {batch.status == 'Faulted' ? (
                    <ErrorOutlineOutlined sx={{ marginRight: '4%', fontSize: '110%', color: '#d92324' }} />
                  ) : null}

                  {t(`callsSchedule.collectionStatus.${batch.status.toLowerCase()}`)}
                </Typography>
              </Grid>
              <Grid
                item
                zeroMinWidth
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  rowGap: (theme) => theme.spacing(2),
                }}
              >
                <Typography
                  variant="body2"
                  title={t('callsSchedule.collection.nextCallDate')}
                  noWrap
                  sx={{
                    margin: 0,
                    fontWeight: 'bold',
                  }}
                >
                  {t('callsSchedule.collection.nextCallDate')}
                </Typography>
                <Typography component={'p'} sx={{ margin: 0 }}>
                  {batch.nextCallDate ? displayDateWithHour(batch.nextCallDate, timezone) : '-'}
                </Typography>
              </Grid>
              <Grid
                item
                zeroMinWidth
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  rowGap: (theme) => theme.spacing(2),
                }}
              >
                <Typography
                  variant="body2"
                  title={t('callsSchedule.collection.calledRecords')}
                  noWrap
                  sx={{
                    margin: 0,
                    fontWeight: 'bold',
                  }}
                >
                  {t('callsSchedule.collection.calledRecords')}
                </Typography>
                <Typography component={'p'} sx={{ margin: 0 }}>
                  {batch.recordsCount}
                </Typography>
              </Grid>
            </Grid>
          )}
          <Grid
            item
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            {batch.status == 'Unknown' ||
            batch.status == 'Drafted' ||
            batch.status == 'Started' ||
            batch.status == 'Completed' ||
            batch.status == 'Faulted' ? (
              <StartCallingButton disabled={true} />
            ) : null}

            {batch.status == 'Saved' ? <StartCallingButton disabled={false} /> : null}

            {batch.status == 'Processing' ? <StopCallingButton disabled={false} /> : null}

            {batch.status == 'Canceled' ? <StopCallingButton disabled={true} /> : null}

            {batch.immediateCallings == true && batch.recordsAvailableToImmediateCallingCount > 0 ? (
              <StartImmediateCallingsInBatch batch={batch} disabled={false} />
            ) : (
              <StartImmediateCallingsInBatch batch={batch} disabled={true} />
            )}
            <BatchHistory batch={batch} />
            <EditBatchButton batch={batch} />
          </Grid>
        </Grid>
      </Grid>
      <BatchTable isTableVisible={isTableVisible} batchId={isTableVisible ? batch.id : undefined} />
    </ListItem>
  );
};
