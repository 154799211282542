import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import { Heading } from '../../components/heading';
import { AddImmediateCallForm } from './components/add-immediate-call-form';
import { CallsList } from './components/calls-list';
import { checkScenarioAcceptsImmediateCalls } from './hooks/check-scenario-accepts-immediate-calls';
import { ErrorOutlineOutlined } from '@mui/icons-material';

export const CallsPage: React.FC = () => {
  const { t } = useTranslation();

  const scenarioAccepts = checkScenarioAcceptsImmediateCalls();

  return (
    <Stack
      sx={{
        height: '100%',
        backgroundColor: 'background.paper',
        overflowY: 'auto',
      }}
    >
      <Heading name={t('call.heading')} className={'intercom-heading-calls'} />
      {scenarioAccepts.data?.acceptsImmediateCalls === true ? (
        <Stack
          spacing={2}
          sx={{
            px: 3,
          }}
        >
          <AddImmediateCallForm />
          <CallsList />
        </Stack>
      ) : (
        <Stack>
          <ErrorOutlineOutlined
            sx={{
              marginLeft: 'auto',
              marginRight: 'auto',
              marginBottom: 10,
              marginTop: 10,
              fontSize: '500%',
              color: 'orange',
            }}
          ></ErrorOutlineOutlined>
          <Typography sx={{ margin: 'auto' }}>Twój scenariusz nie obsługuje wydzwonienia natychmiastowego.</Typography>
        </Stack>
      )}
    </Stack>
  );
};
