import { useContext } from 'react';
import { useQuery } from 'react-query';
import { UseQueryResult } from 'react-query/types/react/types';
import { identityHttpClient } from '../../../common/utils';
import { useAuth } from '../../auth';
import { UsersContext } from '../provider/users-context.provider';
import { UserType } from '../types';

type ResponseType = {
  totalCount: number;
  users: UserType[];
};

export const useUsersQuery = (): UseQueryResult<ResponseType, Error> => {
  const { authenticated, tokenParsed } = useAuth();
  const usersContext = useContext(UsersContext);
  const tenantId = tokenParsed?.tenant_id ? tokenParsed?.tenant_id[0] : '';

  if (usersContext.scenarioId.value === 'all') {
    return useQuery<ResponseType, Error>(
      ['all-users', usersContext.scenarioId.value, usersContext.pageNumber.value, usersContext.tenantGroup.value],
      async () => {
        const response = await identityHttpClient.get<ResponseType>(
          `/Groups/${usersContext.tenantGroup.value}/members/complete-details?PageNumber=${
            usersContext.pageNumber.value
          }&PageSize=${10}`,
        );
        return response.data;
      },
      {
        enabled: authenticated && Boolean(usersContext.scenarioId.value) && Boolean(usersContext.tenantGroup.value),
        staleTime: 5000,
      },
    );
  } else {
    return useQuery<ResponseType, Error>(
      ['users', usersContext.scenarioId.value, usersContext.pageNumber.value],
      async () => {
        const response = await identityHttpClient.get<ResponseType>(
          `/Users/scenario/${usersContext.scenarioId.value}/${tenantId}?PageNumber=${
            usersContext.pageNumber.value
          }&PageSize=${10}`,
        );
        return response.data;
      },
      {
        enabled: authenticated && Boolean(usersContext.scenarioId.value),
        staleTime: 5000,
      },
    );
  }
};
