import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ExpandMore } from '@mui/icons-material';
import { Button, Grid, ListItem, Typography } from '@mui/material';
import { useDeviceQuery } from '../../../../common/hooks';
import { ScenarioCollectionType } from '../../enums';
import { CollectionType } from '../../types';
import { CollectionValues } from './collection-values';

type Props = {
  collection: CollectionType;
};

const displayCollectionType = (type: ScenarioCollectionType) => {
  const collectionType: { [key in ScenarioCollectionType]: string } = {
    [ScenarioCollectionType.Editable]: 'scenarioConfigurator.collectionType.editable',
    [ScenarioCollectionType.EditableValues]: 'scenarioConfigurator.collectionType.editableValues',
    [ScenarioCollectionType.Static]: 'scenarioConfigurator.collectionType.static',
    [ScenarioCollectionType.Hidden]: 'scenarioConfigurator.collectionType.hidden',
  };
  return collectionType[type];
};

export const CollectionItem: React.FC<Props> = ({ collection }) => {
  const { t } = useTranslation();
  const [isValuesVisible, setIsValuesVisible] = useState(false);
  const { isDesktop, isMobile } = useDeviceQuery();

  return (
    <ListItem
      key={collection.id}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        px: {
          xs: 0,
          md: 3,
        },
        py: {
          xs: 0,
          md: 1,
        },
      }}
    >
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        wrap="nowrap"
        sx={{
          height: (theme) => theme.spacing(9),
          backgroundColor: 'background.default',
          borderRadius: 1,
          px: 3,
          py: 2,
          columnGap: (theme) => theme.spacing(isMobile ? 2 : 5),
          borderBottomRightRadius: isValuesVisible ? 0 : 1,
          borderBottomLeftRadius: isValuesVisible ? 0 : 1,
        }}
      >
        <Grid
          item
          md={isDesktop ? 4 : 12}
          zeroMinWidth
          sx={{
            display: 'flex',
            alignItems: 'center',
            columnGap: (theme) => theme.spacing(isMobile ? 2 : 3),
            marginRight: 2,
          }}
        >
          <Button
            color="primary"
            variant="contained"
            startIcon={<ExpandMore />}
            onClick={() => setIsValuesVisible((prev) => !prev)}
            sx={{
              width: 32,
              height: 32,
              minHeight: 32,
              minWidth: 32,
              borderRadius: 50,
              '& .MuiButton-startIcon': {
                margin: 0,
                transform: isValuesVisible ? 'rotate(0.5turn)' : undefined,
              },
            }}
          />
          <Typography
            variant="h5"
            title={collection.name}
            noWrap
            sx={{
              fontWeight: 'bold',
              fontSize: 18,
            }}
          >
            {collection.name}
          </Typography>
        </Grid>
        <Grid
          item
          md={8}
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            paddingLeft: 1,
          }}
        >
          {isDesktop && (
            <Grid container spacing={4} justifyContent="space-around" wrap="nowrap">
              <Grid
                item
                zeroMinWidth
                sx={{
                  minWidth: 134,
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  rowGap: (theme) => theme.spacing(2),
                }}
              >
                <Typography
                  variant="body2"
                  noWrap
                  sx={{
                    margin: 0,
                    fontWeight: 'bold',
                  }}
                >
                  {t(displayCollectionType(collection.collectionType))}
                </Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
      <CollectionValues
        isValuesVisible={isValuesVisible}
        collectionKeyName={isValuesVisible ? collection.keyName : undefined}
        collectionType={collection.collectionType}
      />
    </ListItem>
  );
};
