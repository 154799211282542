import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { Check, ExpandLess, ExpandMore } from '@mui/icons-material';
import { Collapse, List, ListItemButton, ListItemText } from '@mui/material';
import { useAddUserToGroup, useDeleteUserFromGroup } from '../../hooks';
import { GroupType } from '../../types';

type Props = {
  refetch: () => void;
  userGroups: string[] | undefined;
  scenario: GroupType;
  userId: string;
};

export const Scenario: React.VFC<Props> = ({ refetch, userGroups, scenario, userId }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { mutate: mutateAddToGroup } = useAddUserToGroup();
  const { mutate: mutateDeleteFromGroup } = useDeleteUserFromGroup();
  const [open, setOpen] = React.useState(false);
  const handleClick = () => {
    setOpen(!open);
  };
  const handleGroupClick = (groupId: string) => {
    if (isUserGroup(groupId)) {
      mutateDeleteFromGroup(
        {
          groupId,
          userId,
        },
        {
          onSuccess: async () => {
            enqueueSnackbar(t('users.snackbar.successfullyEditedGroup'), { variant: 'success' });
            await refetch();
          },
          onError: async () => {
            enqueueSnackbar(t('users.snackbar.failedToEditGroup'), { variant: 'error' });
          },
        },
      );
    } else {
      mutateAddToGroup(
        {
          groupId,
          userId,
        },
        {
          onSuccess: async () => {
            enqueueSnackbar(t('users.snackbar.successfullyEditedGroup'), { variant: 'success' });
            await refetch();
          },
          onError: async () => {
            enqueueSnackbar(t('users.snackbar.failedToEditGroup'), { variant: 'error' });
          },
        },
      );
    }
  };

  const isUserGroup = (id: string) => {
    return userGroups?.includes(id);
  };

  const isGroupDisabled = (groupId: string) => {
    const scenarioGroups = scenario.subGroups.map((group) => group.id);

    let isAnyGroupSelectedInScenario = false;
    userGroups?.forEach((group) => {
      if (scenarioGroups.includes(group)) {
        isAnyGroupSelectedInScenario = true;
      }
    });

    return isAnyGroupSelectedInScenario && !isUserGroup(groupId);
  };

  return (
    <>
      <ListItemButton onClick={handleClick}>
        <ListItemText primary={scenario.name} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {scenario?.subGroups.map((group) => (
            <ListItemButton
              key={group.id}
              sx={{ pl: 4 }}
              onClick={() => handleGroupClick(group.id)}
              disabled={isGroupDisabled(group.id)}
            >
              <ListItemText primary={group.name} />
              {isUserGroup(group.id) ? <Check /> : null}
            </ListItemButton>
          ))}
        </List>
      </Collapse>
    </>
  );
};
