import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ExpandMore } from '@mui/icons-material';
import { Box, Button, FormControl, Grid, InputLabel, Menu, MenuItem, Select } from '@mui/material';
import { useModal } from '../../../../common/hooks';
import PaginationComponent from '../../../../components/pagination-component/pagination-component';
import { useScenariosQuery } from '../../../scenarios/hooks';
import { useGroupsQuery, useUsersQuery } from '../../hooks';
import { UsersContext } from '../../provider/users-context.provider';
import { AddExistingUserModal } from '../add-existing-user-modal';
import { CreateUserModal } from '../create-user-modal';

export const UsersBar: React.FC = () => {
  const { t } = useTranslation();
  const { pageNumber, scenarioId, tenantGroup } = useContext(UsersContext);
  const { data: scenariosData } = useScenariosQuery();
  const { data: group } = useGroupsQuery();
  const { data: usersData, refetch } = useUsersQuery();
  const [selectedScenario, setSelectedScenario] = useState<string | null>(scenarioId.value);
  const [totalCount, setTotalCount] = useState(usersData?.totalCount || 0);
  const allUsersScenario = 'all';
  const createUserModal = useModal();
  const addExistingUserModal = useModal();
  const [usersAnchorEl, setUsersAnchorEl] = React.useState<null | HTMLElement>(null);
  const isUsersMenuOpen = Boolean(usersAnchorEl);
  const handleUsersAnchorClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setUsersAnchorEl(event.currentTarget);
  };
  const handleUsersMenuClose = () => {
    setUsersAnchorEl(null);
  };

  useEffect(() => {
    const scenario = window.localStorage.getItem('selectedScenario');
    setSelectedScenario(scenario ?? allUsersScenario);
  }, []);

  useEffect(() => {
    if (group) {
      tenantGroup.set(group?.id);
    }
  }, [group]);

  useEffect(() => {
    if (selectedScenario != null) {
      window.localStorage.setItem('selectedScenario', selectedScenario);
      scenarioId.set(selectedScenario);
    }
  }, [selectedScenario]);

  useEffect(() => {
    if (typeof usersData?.totalCount !== 'number') return;
    setTotalCount(usersData?.totalCount || 0);
  }, [usersData?.totalCount]);

  return (
    <>
      <Box
        sx={{
          width: '100%',
          paddingTop: {
            sm: 1.5,
          },
          paddingBottom: {
            sm: 1.5,
          },
          backgroundColor: 'background.paper',
        }}
      >
        <Grid container spacing={1} justifyContent={'flex-end'}>
          <Grid
            item
            sx={{
              width: {
                xs: '100%',
                md: 'unset',
              },
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: {
                sm: 'flex-end',
              },
            }}
          >
            <FormControl
              variant="outlined"
              sx={{
                width: {
                  xs: '100%',
                  sm: 347,
                },
              }}
            >
              <InputLabel margin="dense" htmlFor="report-select" id="report-select-label">
                {t('users.selectScenario')}
              </InputLabel>
              <Select
                disabled={!scenariosData}
                defaultValue={selectedScenario || ''}
                value={selectedScenario || ''}
                labelId="report-select-label"
                id="report-select"
                label={t('users.selectScenario')}
                onChange={(e) => {
                  pageNumber.set(1);
                  setSelectedScenario(`${e.target.value}`);
                }}
                IconComponent={ExpandMore}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      marginTop: 1,
                      backgroundColor: 'common.white',
                      border: (theme) => `1px solid ${theme.palette.background.default}`,
                      borderRadius: 1,
                      boxShadow: 'none',
                    },
                  },
                  MenuListProps: {
                    sx: {
                      padding: 1,
                    },
                  },
                }}
                sx={{
                  color: 'primary.main',
                  height: 48,
                  minWidth: 270,
                }}
              >
                <MenuItem
                  value={allUsersScenario}
                  sx={{
                    color: 'primary.main',
                  }}
                >
                  {t('users.allUsers')}
                </MenuItem>
                {scenariosData?.scenarioCollection.map((scenario) => (
                  <MenuItem
                    key={scenario.scenarioId}
                    value={scenario.scenarioId}
                    sx={{
                      color: 'primary.main',
                    }}
                  >
                    {scenario.scenarioName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid
            item
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: {
                sm: 'flex-end',
              },
              flexWrap: {
                xs: 'wrap-reverse',
                sm: 'nowrap',
              },
              width: {
                xs: '100%',
                sm: 'auto',
              },
            }}
          >
            {!!totalCount && (
              <PaginationComponent
                count={totalCount || 10}
                page={pageNumber.value - 1 || 0}
                pageNumber={pageNumber}
                rowsPerPage={10}
              />
            )}
            <Button
              disabled={selectedScenario === allUsersScenario}
              onClick={handleUsersAnchorClick}
              color="primary"
              type="button"
              variant="contained"
              endIcon={<ExpandMore />}
              sx={{
                whiteSpace: 'nowrap',
                width: {
                  xs: '100%',
                  sm: 'auto',
                },
                height: 48,
                marginBottom: {
                  xs: 1,
                  sm: 0,
                },
                marginLeft: {
                  xs: 0,
                  sm: 1,
                },
              }}
            >
              {t('users.title')}
            </Button>
            <Menu anchorEl={usersAnchorEl} open={isUsersMenuOpen} onClose={handleUsersMenuClose}>
              <MenuItem
                onClick={() => {
                  createUserModal.open();
                  handleUsersMenuClose();
                }}
              >
                {t('users.addUser')}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  addExistingUserModal.open();
                  handleUsersMenuClose();
                }}
              >
                {t('users.addExistingScenarioUser')}
              </MenuItem>
            </Menu>
          </Grid>
        </Grid>
      </Box>
      {selectedScenario && selectedScenario !== allUsersScenario ? (
        <>
          <CreateUserModal handleModalClose={createUserModal.close} modalOpen={createUserModal.isOpen} />
          <AddExistingUserModal
            handleModalClose={addExistingUserModal.close}
            modalOpen={addExistingUserModal.isOpen}
            usersRefetch={refetch}
          />
        </>
      ) : null}
    </>
  );
};
