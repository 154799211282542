import React, { useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import LoadingButton from '@mui/lab/LoadingButton';
import { TableCell, TableRow } from '@mui/material';
import { convertParamsToArray } from '../../../../common/utils';
import { InputText } from '../../../../components/inputs';
import { ScenarioCollectionType } from '../../enums';
import { useUpdateCollectionValues } from '../../hooks';
import { CollectionValuesDocumentType } from '../../types';

type Props = {
  documentValue: CollectionValuesDocumentType;
  collectionType: ScenarioCollectionType;
  collectionKeyName?: string;
  refetch: () => void;
};

type FormData = {
  values: {
    [key: string]: string;
  };
};

export const CollectionValuesForm: React.FC<Props> = ({
  documentValue,
  collectionType,
  collectionKeyName,
  refetch,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { mutate, isLoading: isUpdateCollectionValuesLoading } = useUpdateCollectionValues();

  const form = useForm<FormData>();
  const { _id, ...documentWithoutId } = documentValue;

  const onSubmit = useCallback(
    (data: FormData) => {
      const { values } = data;
      mutate(
        {
          collectionKeyName: collectionKeyName ?? '',
          documents: [
            {
              documentId: _id ?? '',
              data: values,
            },
          ],
        },
        {
          onSuccess: async () => {
            enqueueSnackbar(t('scenarioConfigurator.editFeedback.success'), { variant: 'success' });
            await refetch();
            form.reset(data);
          },
          onError: async () => {
            enqueueSnackbar(t('scenarioConfigurator.editFeedback.error'), { variant: 'error' });
          },
        },
      );
    },
    [documentValue],
  );

  return (
    <>
      {convertParamsToArray(documentWithoutId).map(([paramKey, paramValue]) => (
        <TableRow
          hover
          key={paramKey}
          sx={{
            '& .MuiTableCell-root': {
              color: 'primary.main',
              borderBottom: 'none',
            },
          }}
        >
          <TableCell>{paramKey}</TableCell>
          {collectionType === ScenarioCollectionType.EditableValues ? (
            <TableCell
              sx={{
                py: 1,
              }}
            >
              <form
                onSubmit={form.handleSubmit(onSubmit)}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Controller
                  name={`values.${paramKey}`}
                  control={form.control}
                  defaultValue={paramValue}
                  rules={{ required: t('validation.required') as string }}
                  render={({ field, fieldState: { error } }) => (
                    <InputText
                      multiline
                      fullWidth
                      margin={'none'}
                      type={'text'}
                      error={error}
                      name={field.name}
                      onChange={field.onChange}
                      value={field.value}
                    />
                  )}
                />
                <LoadingButton
                  disabled={!form.formState.isDirty}
                  type="submit"
                  variant="contained"
                  color="primary"
                  loading={isUpdateCollectionValuesLoading}
                  sx={{
                    ml: 2,
                  }}
                >
                  {t('scenarioConfigurator.edit')}
                </LoadingButton>
              </form>
            </TableCell>
          ) : (
            <TableCell>{paramValue}</TableCell>
          )}
        </TableRow>
      ))}
    </>
  );
};
