import React from 'react';
import { SvgIcon, SxProps } from '@mui/material';

export const CallSchedule: React.FC<{ sx?: SxProps; fill: string }> = (props) => {
  return (
    <SvgIcon sx={props.sx} fill="none" xmlns="http://www.w3.org/2000/svg">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M16.4955 3C14.0115 3 12 5.016 12 7.5C12 9.984 14.0115 12 16.4955 12C18.984 12 21 9.984 21 7.5C21 5.016 18.984 3 16.4955 3ZM16.5 11.1C14.511 11.1 12.9 9.489 12.9 7.5C12.9 5.511 14.511 3.9 16.5 3.9C18.489 3.9 20.1 5.511 20.1 7.5C20.1 9.489 18.489 11.1 16.5 11.1Z"
          fill={props.fill}
        />
        <path d="M16.75 5H16V7.62295L18.625 9L19 8.4623L16.75 7.29508V5Z" fill={props.fill} />
        <path
          d="M6.62 10.79C8.06 13.62 10.38 15.93 13.21 17.38L15.41 15.18C15.68 14.91 16.08 14.82 16.43 14.94C17.55 15.31 18.76 15.51 20 15.51C20.55 15.51 21 15.96 21 16.51V20C21 20.55 20.55 21 20 21C10.61 21 3 13.39 3 4C3 3.45 3.45 3 4 3H7.5C8.05 3 8.5 3.45 8.5 4C8.5 5.25 8.7 6.45 9.07 7.57C9.18 7.92 9.1 8.31 8.82 8.59L6.62 10.79Z"
          fill={props.fill}
        />
      </svg>
    </SvgIcon>
  );
};
