import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Heading } from '../../components/heading';
import { HistoryBar } from './components/history-bar';
import { HistoryTable } from './components/history-table';
import { HistoryContextProvider } from './provider/history-context.provider';
import { TimeZoneContext } from '../scenarios/provider/timezone-context.provider';

export const HistoryPage: React.FC = () => {
  const { t } = useTranslation();
  const { timezone } = useContext(TimeZoneContext);
  console.log('timezone', timezone);

  return (
    <HistoryContextProvider>
      <Heading name={t('history.heading')}>
        <HistoryBar />
      </Heading>
      <HistoryTable />
    </HistoryContextProvider>
  );
};
