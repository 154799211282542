import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import { Heading } from '../../components/heading';
import { BatchList } from './components/batch';
import { CallsScheduleBar } from './components/calls-schedule-bar';
import { BatchListPaginationContextProvider, CallsScheduleProvider } from './provider';

export const CallsSchedulePage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Stack
      sx={{
        height: '100%',
        backgroundColor: 'background.paper',
        overflowY: 'auto',
      }}
    >
      <BatchListPaginationContextProvider>
        <CallsScheduleProvider>
          <Heading name={t('callsSchedule.heading')} className={'intercom-heading-calls-schedule'}>
            <CallsScheduleBar />
          </Heading>
          <BatchList />
        </CallsScheduleProvider>
      </BatchListPaginationContextProvider>
    </Stack>
  );
};
