import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Check, Close, Delete, Edit, Info } from '@mui/icons-material';
import { Box, Button, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import { useModal } from '../../../../common/hooks';
import { BasicSkeletons } from '../../../../components/basic-skeletons';
import { RoundedButton } from '../../../../components/rounded-button';
import { TableHeaderCell } from '../../../../components/table-header-cell';
import { routes } from '../../../../routes';
import { useScenarioUsersQuery } from '../../hooks';
import { UserType } from '../../types';
import { ConfirmDeleteUserFromGroupModal } from '../confirm-delete-user-from-group-modal';
import { EditUserModal } from '../edit-user-modal';

export const UsersTable: React.FC = () => {
  const { t } = useTranslation();
  const { data, isLoading, isError } = useScenarioUsersQuery();
  const [user, setUser] = useState<UserType | null>(null);
  const confirmDeleteUserFromGroupModal = useModal();
  const editUserModal = useModal();

  const renderTableHead = () => (
    <TableRow
      sx={{
        '& .MuiTableCell-root': {
          whiteSpace: 'nowrap',
        },
      }}
    >
      <TableHeaderCell cellName={t('users.user.username')} />
      <TableHeaderCell cellName={t('users.user.email')} />
      <TableHeaderCell cellName={t('users.user.firstName')} />
      <TableHeaderCell cellName={t('users.user.lastName')} />
      <TableHeaderCell cellName={t('users.user.enabled')} align="center" />
      <TableHeaderCell cellName={t('users.user.emailVerified')} align="center" />
      <TableHeaderCell cellName={t('users.options')} align="center" />
    </TableRow>
  );

  const renderTableBody = (innerProps: { users: UserType[] }) => {
    const { users } = innerProps;
    return users?.map((user: UserType) => (
      <TableRow key={user.id}>
        <TableCell>{user.username}</TableCell>
        <TableCell>{user.email}</TableCell>
        <TableCell>{user.firstName}</TableCell>
        <TableCell>{user.lastName}</TableCell>
        <TableCell align="center">{user.enabled ? <Check /> : <Close />}</TableCell>
        <TableCell align="center">{user.emailVerified ? <Check /> : <Close />}</TableCell>
        <TableCell align="center">
          <Box
            sx={{
              display: 'flex',
              columnGap: 1,
              rowGap: 1,
              alignItems: 'center',
              justifyContent: 'center',
              margin: '-10px',
            }}
          >
            <Tooltip title={t('users.details') as string} arrow disableInteractive>
              <Button
                component={Link}
                to={routes.user.link({ id: user.id })}
                sx={{
                  width: 36,
                  height: 36,
                  minWidth: 'unset',
                  borderRadius: '50%',
                  '& svg': {
                    color: 'common.white',
                    width: '26px',
                    height: '26px',
                  },
                }}
              >
                <Info />
              </Button>
            </Tooltip>
            <Tooltip title={t('users.edit') as string} arrow disableInteractive>
              <div>
                <RoundedButton
                  onClick={() => {
                    setUser(user);
                    editUserModal.open();
                  }}
                  icon={<Edit />}
                />
              </div>
            </Tooltip>
            <Tooltip title={t('users.deleteFromGroup') as string} arrow disableInteractive>
              <div>
                <RoundedButton
                  onClick={() => {
                    setUser(user);
                    confirmDeleteUserFromGroupModal.open();
                  }}
                  icon={<Delete />}
                />
              </div>
            </Tooltip>
          </Box>
        </TableCell>
      </TableRow>
    ));
  };
  return (
    <Box
      sx={{
        overflowX: 'auto',
        overflowY: 'hidden',
        backgroundColor: 'background.paper',
        borderColor: 'background.paper',
        borderStyle: 'solid',
        borderLeftWidth: 24,
        borderRightWidth: 24,
        borderTopWidth: 10,
        borderBottomWidth: 50,
        boxSizing: 'border-box',
      }}
    >
      <Table sx={{ width: '100%' }}>
        <TableHead>{renderTableHead()}</TableHead>
        {data?.users ? <TableBody>{renderTableBody({ users: data?.users })}</TableBody> : null}
      </Table>
      {isError || data?.totalCount === 0 ? (
        <Typography
          sx={{
            textAlign: 'center',
            fontSize: '1.1rem',
            fontWeight: 700,
            width: '100%',
            mt: 3,
          }}
        >
          {t('users.noResults')}
        </Typography>
      ) : null}
      {isLoading ? <BasicSkeletons number={10} height={60} /> : null}

      <ConfirmDeleteUserFromGroupModal
        handleModalClose={confirmDeleteUserFromGroupModal.close}
        modalOpen={confirmDeleteUserFromGroupModal.isOpen}
        user={user}
      />
      {user ? (
        <EditUserModal handleModalClose={editUserModal.close} modalOpen={editUserModal.isOpen} user={user} />
      ) : null}
    </Box>
  );
};
