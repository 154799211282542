import { useMutation, UseMutationResult } from 'react-query';
import { MutationErrorType } from '../../../common/types';
import { identityHttpClient } from '../../../common/utils';

type Body = {
  userId: string;
  groupId: string;
};

type Response = unknown;

export const useDeleteUserFromGroup = (): UseMutationResult<Response, MutationErrorType, Body> => {
  return useMutation(async (body) => {
    const response = await identityHttpClient.delete(`/Groups/${body.groupId}/members/delete/${body.userId}`);
    return response.data;
  });
};
