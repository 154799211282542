import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { ExpandMore } from '@mui/icons-material';
import { Box, FormControl, MenuItem, Select, Stack, Tooltip, Typography } from '@mui/material';
import { theme } from '../../theme';
import { useScenario } from '../scenarios/hooks';
import { TranslationContext } from '../translation';
import { navLinks } from './components/nav-links';

export const SideNavigation: React.FC<React.PropsWithChildren> = (props) => {
  const { t } = useTranslation();
  const scenario = useScenario();
  const { currentLanguage, setCurrentLanguage } = useContext(TranslationContext);

  return (
    <Stack direction={'row'} flexGrow={1}>
      <Box
        sx={{
          display: {
            xs: 'none',
            md: 'flex',
          },
          width: 88,
          minWidth: 88,
        }}
      >
        <Stack
          alignItems={'center'}
          sx={{
            position: 'fixed',
            backgroundColor: 'background.default',
            width: 88,
            paddingTop: '59px',
            '& a + a': {
              marginTop: 3,
            },
            height: '100vh',
          }}
        >
          {navLinks.map((x) => (
            <NavLink
              key={x.name}
              to={x.getLink(scenario.id)}
              style={({ isActive }) => ({
                backgroundColor: isActive ? theme.palette.common.white : 'transparent',
                borderRadius: '50%',
              })}
            >
              <Tooltip
                placement={'right'}
                title={
                  <Typography paragraph fontSize={12} m={0}>
                    {t(x.name)}
                  </Typography>
                }
              >
                <Box>{x.component}</Box>
              </Tooltip>
            </NavLink>
          ))}
        </Stack>
        <FormControl style={{ position: 'fixed', bottom: 0, left: 0, margin: '16px 24px' }}>
          <Select
            disableUnderline
            variant="standard"
            labelId="lang-select-label"
            id="lang-select"
            label={t('selectLang')}
            value={currentLanguage}
            onChange={(e) => setCurrentLanguage(`${e.target.value}`)}
            IconComponent={ExpandMore}
            sx={{
              color: 'primary.main',
              '& .MuiSelect-icon': {
                color: 'primary.main',
              },
            }}
          >
            {['en', 'pl', 'es'].map((lang, index) => (
              <MenuItem key={index} value={lang}>
                {lang}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box
        sx={{
          flex: 1,
          overflowX: 'auto',
        }}
      >
        {props.children}
      </Box>
    </Stack>
  );
};
