import { useQuery } from 'react-query';
import { UseQueryResult } from 'react-query/types/react/types';
import { identityHttpClient } from '../../../common/utils';
import { useAuth } from '../../auth';
import { GroupType } from '../types';

type ResponseType = GroupType;

export const useGroupsQuery = (): UseQueryResult<ResponseType, Error> => {
  const { authenticated, tokenParsed } = useAuth();
  const tenantId = tokenParsed?.tenant_id ? tokenParsed?.tenant_id[0] : '';

  return useQuery<ResponseType, Error>(
    ['groups', tenantId],
    async () => {
      const response = await identityHttpClient.get<ResponseType>(`/Groups/tenant/${tenantId}`);
      return response.data;
    },
    {
      enabled: authenticated,
      staleTime: 5000,
    },
  );
};
