import React from 'react';
import { Stack } from '@mui/material';
import { DashboardContent, DashboardNav } from './components';
import { DashboardContextProvider } from './provider/dashboard-context.provider';

export const DashboardPage: React.FC = () => {
  return (
    <DashboardContextProvider>
      <Stack
        sx={{
          flexGrow: 1,
          backgroundColor: 'common.white',
        }}
      >
        <DashboardNav />
        <DashboardContent />
      </Stack>
    </DashboardContextProvider>
  );
};
