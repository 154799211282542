import { useQuery } from 'react-query';
import { UseQueryResult } from 'react-query/types/react/types';
import { identityHttpClient } from '../../../common/utils';
import { useAuth } from '../../auth';
import { UserType } from '../types';

type ResponseType = {
  user: UserType;
};

export const useUserQuery = (userId: string | undefined): UseQueryResult<ResponseType, Error> => {
  const { authenticated } = useAuth();

  return useQuery<ResponseType, Error>(
    ['user', userId],
    async () => {
      const response = await identityHttpClient.get<ResponseType>(`/Users/${userId}`);
      return response.data;
    },
    {
      enabled: authenticated,
      staleTime: 5000,
    },
  );
};
