import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { Box, Button, Typography } from '@mui/material';
import { BasicModal } from '../../../../components/basic-modal';
import { useDeleteUserFromGroup, useUsersQuery } from '../../hooks';
import { UserType } from '../../types';

type Props = {
  handleModalClose: () => void;
  modalOpen: boolean;
  user: UserType | null;
};

export const ConfirmDeleteUserFromGroupModal: React.FC<Props> = ({ handleModalClose, modalOpen, user }) => {
  const { t } = useTranslation();
  const users = useUsersQuery();
  const { enqueueSnackbar } = useSnackbar();
  const { mutate } = useDeleteUserFromGroup();

  const remove = () => {
    mutate(
      {
        userId: user?.id ?? '',
        groupId: user?.groupId ?? '',
      },
      {
        onSuccess: () => {
          handleModalClose();
          enqueueSnackbar(t('users.snackbar.successfullyDeletedFromGroup'), { variant: 'success' });
          users.refetch();
        },
        onError: async () => {
          handleModalClose();
          enqueueSnackbar(t('users.snackbar.failedToDeleteFromGroup'), { variant: 'error' });
        },
      },
    );
  };

  const ModalBodyComponent = (
    <Box>
      <>
        <Typography variant="body2" sx={{ marginBottom: 3 }}>
          {t('users.deleteUserFromGroupMessage')}
        </Typography>
      </>
      <Box display="flex" justifyContent="flex-end">
        <Button color="primary" variant="outlined" onClick={handleModalClose} sx={{ marginRight: 1 }}>
          {t('cancel')}
        </Button>
        <Button type="submit" color="error" variant="contained" onClick={remove}>
          {t('delete')}
        </Button>
      </Box>
    </Box>
  );

  return (
    <BasicModal
      modalOpen={modalOpen}
      handleModalClose={handleModalClose}
      modalBody={ModalBodyComponent}
      title={t('users.deleteUserFromGroupTitle')}
    />
  );
};
