import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import Person from '@mui/icons-material/Person';
import { Box, Button, Divider, FormGroup, Skeleton, Typography } from '@mui/material';
import { BasicSkeletons } from '../../../../components/basic-skeletons';
import { InputText } from '../../../../components/inputs';
import { useUpdateUserData, useUserQuery } from '../../hooks';

type FormData = {
  firstName?: string;
  lastName?: string;
  username?: string;
  email?: string;
};

export const UserProfileForm: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { data, refetch, isLoading: isFormLoading } = useUserQuery(id);
  const { handleSubmit, control } = useForm<FormData>();
  const { enqueueSnackbar } = useSnackbar();
  const { mutate, isLoading: isUpdateLoading } = useUpdateUserData(id);

  const submit = React.useCallback(
    (formData: FormData) => {
      mutate(formData, {
        onSuccess: () => {
          refetch();
          enqueueSnackbar(t('users.snackbar.successfullyUpdated'), { variant: 'success' });
        },
        onError: async () => {
          enqueueSnackbar(t('users.snackbar.failedToUpdate'), { variant: 'error' });
        },
      });
    },
    [mutate],
  );
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        p: 4,
        boxShadow: '0px 0px 25px -10px rgba(66, 68, 90, 0.3)',
        borderRadius: '15px',
        width: {
          xs: '100%',
          lg: 600,
        },
      }}
    >
      <Person fontSize="large" color="primary" />
      <Typography variant="h5">{t('users.user.userData')}</Typography>
      <Divider
        sx={{
          margin: '20px 0',
          width: '100%',
        }}
      />
      {isFormLoading ? (
        <Box
          sx={{
            display: 'flex',
            padding: '10px',
            justifyContent: 'space-evenly',
            flexDirection: 'column',
            width: 1,
          }}
        >
          <BasicSkeletons number={4} height={75} width={'100%'} />
          <Skeleton variant="text" width={90} height={60} sx={{ alignSelf: 'end' }} />
        </Box>
      ) : (
        <form style={{ maxWidth: '500px', marginTop: 10 }} onSubmit={handleSubmit(submit)}>
          <Controller
            name="username"
            control={control}
            defaultValue={data?.user.username}
            rules={{ required: t('validation.required') as string }}
            render={({ field, fieldState: { error } }) => (
              <InputText
                fullWidth
                margin={'dense'}
                label={t('users.user.username')}
                error={error}
                name={field.name}
                onChange={field.onChange}
                value={field.value || ''}
                disabled
              />
            )}
          />
          <Controller
            name="firstName"
            control={control}
            defaultValue={data?.user.firstName}
            rules={{ required: t('validation.required') as string }}
            render={({ field, fieldState: { error } }) => (
              <InputText
                fullWidth
                margin={'dense'}
                label={t('users.user.firstName')}
                error={error}
                name={field.name}
                onChange={field.onChange}
                value={field.value || ''}
              />
            )}
          />
          <Controller
            name="lastName"
            control={control}
            defaultValue={data?.user.lastName}
            rules={{ required: t('validation.required') as string }}
            render={({ field, fieldState: { error } }) => (
              <InputText
                fullWidth
                margin={'dense'}
                label={t('users.user.lastName')}
                error={error}
                name={field.name}
                onChange={field.onChange}
                value={field.value || ''}
              />
            )}
          />
          <Controller
            name="email"
            control={control}
            defaultValue={data?.user.email}
            rules={{ required: t('validation.required') as string }}
            render={({ field, fieldState: { error } }) => (
              <InputText
                disabled
                fullWidth
                margin={'dense'}
                label={t('users.user.email')}
                error={error}
                name={field.name}
                onChange={field.onChange}
                value={field.value || ''}
              />
            )}
          />
          <FormGroup
            row
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={isUpdateLoading}
              sx={{
                marginTop: 3,
                width: {
                  xs: '100%',
                  sm: 'auto',
                },
              }}
            >
              {t('edit')}
            </Button>
          </FormGroup>
        </form>
      )}
    </Box>
  );
};
