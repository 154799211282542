import { useMutation, UseMutationResult } from 'react-query';
import { MutationErrorType } from '../../../common/types';
import { identityHttpClient } from '../../../common/utils';

type Body = {
  userId: string;
  groupFromId: string;
  groupToId: string;
};

type Response = unknown;

export const useChangeUserGroup = (): UseMutationResult<Response, MutationErrorType, Body> => {
  return useMutation(async (body) => {
    const deleteResponse = await identityHttpClient.delete(`/Groups/${body.groupFromId}/members/delete/${body.userId}`);
    if (deleteResponse.status === 200) {
      const addResponse = await identityHttpClient.post(`/Groups/${body.groupToId}/members/add/${body.userId}`);
      return {
        deleteResponse,
        addResponse,
      };
    }
    return deleteResponse.data;
  });
};
