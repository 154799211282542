import { useMutation, UseMutationResult } from 'react-query';
import { MutationErrorType } from '../../../common/types';
import { identityHttpClient } from '../../../common/utils';

type Body = {
  firstName?: string;
  lastName?: string;
  username?: string;
  email?: string;
};

type Response = unknown;

export const useUpdateUserData = (userId: string | undefined): UseMutationResult<Response, MutationErrorType, Body> => {
  return useMutation(async (body) => {
    const response = await identityHttpClient.put(`/Users/${userId}`, body);
    return response.data;
  });
};
