import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ExpandMore } from '@mui/icons-material';
import { Box, Button, Grid, Menu, MenuItem } from '@mui/material';
import { useModal } from '../../../../common/hooks';
import PaginationComponent from '../../../../components/pagination-component/pagination-component';
import { AddExistingUserModal } from '../../../users/components/add-existing-user-modal';
import { useScenarioUsersQuery } from '../../hooks';
import { ScenarioUsersContext } from '../../provider/scenario-users-context.provider';
import { CreateUserModal } from '../create-user-modal';

export const UsersBar: React.FC = () => {
  const { t } = useTranslation();
  const { pageNumber } = useContext(ScenarioUsersContext);
  const { data, refetch } = useScenarioUsersQuery();
  const [totalCount, setTotalCount] = useState(data?.totalCount || 0);
  const createUserModal = useModal();
  const addExistingUserModal = useModal();
  const [usersAnchorEl, setUsersAnchorEl] = React.useState<null | HTMLElement>(null);
  const isUsersMenuOpen = Boolean(usersAnchorEl);
  const handleUsersAnchorClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setUsersAnchorEl(event.currentTarget);
  };
  const handleUsersMenuClose = () => {
    setUsersAnchorEl(null);
  };

  useEffect(() => {
    if (typeof data?.totalCount !== 'number') return;
    setTotalCount(data?.totalCount || 0);
  }, [data?.totalCount]);

  return (
    <>
      <Box
        sx={{
          width: '100%',
          paddingTop: {
            sm: 1.5,
          },
          paddingBottom: {
            sm: 1.5,
          },
          backgroundColor: 'background.paper',
        }}
      >
        <Grid container spacing={1} justifyContent={'flex-end'}>
          <Grid
            item
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: {
                sm: 'flex-end',
              },
              flexWrap: {
                xs: 'wrap-reverse',
                sm: 'nowrap',
              },
              width: {
                xs: '100%',
                sm: 'auto',
              },
            }}
          >
            {!!totalCount && (
              <PaginationComponent
                count={totalCount || 10}
                page={pageNumber.value - 1 || 0}
                pageNumber={pageNumber}
                rowsPerPage={10}
              />
            )}
            <Button
              onClick={handleUsersAnchorClick}
              color="primary"
              type="button"
              variant="contained"
              endIcon={<ExpandMore />}
              sx={{
                whiteSpace: 'nowrap',
                width: {
                  xs: '100%',
                  sm: 'auto',
                },
                height: 48,
                marginBottom: {
                  xs: 1,
                  sm: 0,
                },
                marginLeft: {
                  xs: 0,
                  sm: 1,
                },
              }}
            >
              {t('users.title')}
            </Button>
            <Menu anchorEl={usersAnchorEl} open={isUsersMenuOpen} onClose={handleUsersMenuClose}>
              <MenuItem
                onClick={() => {
                  createUserModal.open();
                  handleUsersMenuClose();
                }}
              >
                {t('scenarioUsers.createNewScenarioUser')}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  addExistingUserModal.open();
                  handleUsersMenuClose();
                }}
              >
                {t('users.addExistingScenarioUser')}
              </MenuItem>
            </Menu>
          </Grid>
        </Grid>
      </Box>
      <CreateUserModal handleModalClose={createUserModal.close} modalOpen={createUserModal.isOpen} />
      <AddExistingUserModal
        handleModalClose={addExistingUserModal.close}
        modalOpen={addExistingUserModal.isOpen}
        usersRefetch={refetch}
      />
    </>
  );
};
