import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { differenceInDays, startOfMonth } from 'date-fns';
import { ExpandMore } from '@mui/icons-material';
import { FormControl, MenuItem, Select, Stack } from '@mui/material';
import { Heading } from '../../../components/heading';
import { DashboardContext } from '../provider/dashboard-context.provider';

const selectDateValues = [
  {
    id: 1,
    value: 'dashboard.today',
  },
  {
    id: 2,
    value: 'dashboard.lastWeek',
  },
  {
    id: 3,
    value: 'dashboard.lastMonth',
  },
];

const countDaysSince = (date: Date, currentDate: Date): number => {
  return differenceInDays(currentDate, date) + 1;
};

export const DashboardNav: React.FC = () => {
  const { days } = useContext(DashboardContext);
  const [selectedDays, setSelectedDays] = useState(days.value);
  const { t } = useTranslation();

  useEffect(() => {
    const today = new Date();
    const options: { [key: number]: number } = {
      1: 1,
      2: 7,
      3: countDaysSince(startOfMonth(today), today),
    };

    days.set(options[selectedDays]);
  }, [selectedDays]);

  return (
    <Heading name={'Dashboard'} className="intercom-heading-dashboard">
      <Stack direction={'row'} flexWrap={'wrap'} justifyContent={'flex-end'}>
        <FormControl variant="outlined">
          <Select
            id="summary-date-filter"
            onChange={(e) => setSelectedDays(Number(e.target.value))}
            value={selectedDays}
            IconComponent={ExpandMore}
            MenuProps={{
              PaperProps: {
                sx: {
                  background: 'common.white',
                  borderWidth: 1,
                  borderStyle: 'solid',
                  borderColor: 'background.default',
                  boxSizing: 'border-box',
                  borderRadius: 4,
                  boxShadow: 'none',
                },
              },
              MenuListProps: {
                sx: {
                  padding: 1,
                },
              },
            }}
            sx={{
              width: {
                xs: 'calc(100vw - 40px)',
                sm: 280,
              },
              height: 48,
              color: 'primary.main',
            }}
          >
            {selectDateValues.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {t(item.value)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
    </Heading>
  );
};
